import { MONTH_NAMES } from "@/src/constants.ts";
import {
  Calendar,
  CalendarCell,
  CalendarGrid,
  DatePicker,
  Dialog,
  Group,
  Heading,
} from "react-aria-components";
import { getLocalTimeZone } from "@internationalized/date";
import Popover from "@/src/components/base/Popover.tsx";
import Button from "@/src/components/base/Button.tsx";
import { parseDate } from "@internationalized/date";
import { useNavigate } from "@tanstack/react-router";

export default function DateSelector({
  selectedDate,
  availableDates,
}: {
  selectedDate: string | undefined;
  availableDates: string[];
}) {
  const navigate = useNavigate();

  const calendarDate = selectedDate ? parseDate(selectedDate) : undefined;
  const dateObject = calendarDate
    ? calendarDate.toDate(getLocalTimeZone())
    : undefined;
  const selectedDateIndex = availableDates.findIndex(
    (availableDate) => availableDate === selectedDate,
  );

  function setSelectedDate(date: string) {
    navigate({
      search: (prevSearch) => ({ ...prevSearch, date }),
    });
  }

  return (
    <div className="flex space-x-2">
      <Button
        isDisabled={
          selectedDateIndex === availableDates.length - 1 ||
          !availableDates.length
        }
        className="flex size-10 items-center justify-center rounded-lg border-gray-300 bg-white p-3 shadow data-[hovered]:bg-gray-200 data-[disabled]:opacity-40 lg:border lg:bg-transparent lg:shadow-none"
        onPress={() => {
          setSelectedDate(availableDates[selectedDateIndex + 1]);
        }}
      >
        {"<"}
      </Button>
      <DatePicker
        onChange={(value) => {
          setSelectedDate(value.toString());
        }}
        value={calendarDate}
        minValue={
          availableDates.length
            ? parseDate(availableDates[availableDates.length - 1])
            : undefined
        }
        maxValue={
          availableDates.length ? parseDate(availableDates[0]) : undefined
        }
        className="flex grow"
      >
        <Group className="flex grow">
          <Button className="flex h-10 grow items-center justify-center text-nowrap rounded-lg border-gray-300 bg-white px-4 text-sm font-medium shadow data-[hovered]:bg-gray-200 lg:w-36 lg:border lg:bg-transparent lg:shadow-none">
            {dateObject
              ? `${dateObject.getDate()} ${MONTH_NAMES[dateObject.getMonth()]} ${dateObject.getFullYear()}`
              : "No date selected"}
          </Button>
        </Group>
        <Popover className="p-4" placement="bottom">
          <Dialog>
            <Calendar
              isDateUnavailable={(date) =>
                !availableDates.find(
                  (availableDate) => availableDate === date.toString(),
                )
              }
            >
              <header className="mb-3 flex justify-between">
                <Button slot="previous">◀</Button>
                <Heading />
                <Button slot="next">▶</Button>
              </header>
              <CalendarGrid>
                {(date) => (
                  <CalendarCell
                    date={date}
                    className={({
                      isHovered,
                      isSelected,
                      isDisabled,
                      isUnavailable,
                    }) => {
                      let extraClassName = "";
                      if (isDisabled || isUnavailable) {
                        extraClassName = "text-gray-300";
                      } else if (isSelected) {
                        extraClassName = "bg-blue-600 text-white";
                      } else if (isHovered) {
                        extraClassName = "bg-blue-100";
                      }
                      return (
                        "rounded-lg px-1 py-0.5 text-center " + extraClassName
                      );
                    }}
                  />
                )}
              </CalendarGrid>
            </Calendar>
          </Dialog>
        </Popover>
      </DatePicker>
      <Button
        isDisabled={selectedDateIndex === 0 || !availableDates.length}
        className="flex size-10 items-center justify-center rounded-lg border-gray-300 bg-white p-3 shadow data-[hovered]:bg-gray-200 data-[disabled]:opacity-40 lg:border lg:bg-transparent lg:shadow-none"
        onPress={() => {
          setSelectedDate(availableDates[selectedDateIndex - 1]);
        }}
      >
        {">"}
      </Button>
    </div>
  );
}
