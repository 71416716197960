import { useState } from "react";
import KeywordsTable from "@/src/components/home/KeywordsModal/KeywordsTable.tsx";
import { Keyword } from "@/src/types.ts";
import Modal from "@/src/components/base/Modal.tsx";
import KeywordsList from "@/src/components/home/KeywordsModal/KeywordsList.tsx";
import Input from "../../base/Input";

export default function KeywordsModal({
  isOpen,
  setIsOpen,
  keywords,
  maxKeywords,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  keywords: Keyword[];
  maxKeywords: number;
}) {
  const [searchText, setSearchText] = useState("");

  const filteredKeywords = keywords.filter(({ label }) => {
    return searchText ? label.includes(searchText) : true;
  });
  const activeKeywordsCount = keywords.filter(({ active }) => active).length;
  const commonProps = {
    keywords: filteredKeywords,
    searchText: searchText,
    canAddKeywords: activeKeywordsCount < maxKeywords,
    canRemoveKeywords: activeKeywordsCount > 1,
  };
  return (
    <>
      <button
        className="ml-auto flex flex-row-reverse items-center gap-x-1 text-xs text-blue-600 lg:flex-row lg:gap-x-2 lg:text-base"
        onClick={() => setIsOpen(true)}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="fill-blue-600"
            d="M23.2031 7.82812C23.3906 8.25 23.25 8.67188 22.9219 9L20.9062 10.8281C20.9531 11.2031 20.9531 11.625 20.9531 12C20.9531 12.4219 20.9531 12.8438 20.9062 13.2188L22.9219 15.0469C23.25 15.3281 23.3906 15.7969 23.2031 16.2188C23.0156 16.7812 22.7812 17.2969 22.5 17.8125L22.2656 18.1875C21.9375 18.7031 21.6094 19.2188 21.2344 19.6406C20.9531 20.0156 20.4844 20.1094 20.0625 19.9688L17.4844 19.1719C16.8281 19.6406 16.125 20.0156 15.4219 20.3438L14.8125 23.0156C14.7188 23.4375 14.3906 23.7656 13.9688 23.8594C13.3125 23.9531 12.6562 24 11.9531 24C11.2969 24 10.6406 23.9531 9.98438 23.8594C9.5625 23.7656 9.23438 23.4375 9.14062 23.0156L8.53125 20.3438C7.78125 20.0156 7.125 19.6406 6.46875 19.1719L3.89062 19.9688C3.46875 20.1094 3 20.0156 2.71875 19.6875C2.34375 19.2188 2.01562 18.7031 1.6875 18.1875L1.45312 17.8125C1.17188 17.2969 0.9375 16.7812 0.75 16.2188C0.5625 15.7969 0.703125 15.375 1.03125 15.0469L3.04688 13.2188C3 12.8438 3 12.4219 3 12C3 11.625 3 11.2031 3.04688 10.8281L1.03125 9C0.703125 8.67188 0.5625 8.25 0.75 7.82812C0.9375 7.26562 1.17188 6.75 1.45312 6.23438L1.6875 5.85938C2.01562 5.34375 2.34375 4.82812 2.71875 4.35938C3 4.03125 3.46875 3.9375 3.89062 4.07812L6.46875 4.875C7.125 4.40625 7.82812 3.98438 8.53125 3.70312L9.14062 1.03125C9.23438 0.609375 9.5625 0.28125 9.98438 0.1875C10.6406 0.09375 11.2969 0 12 0C12.6562 0 13.3125 0.09375 13.9688 0.1875C14.3906 0.234375 14.7188 0.609375 14.8125 1.03125L15.4219 3.70312C16.1719 3.98438 16.8281 4.40625 17.4844 4.875L20.0625 4.07812C20.4844 3.9375 20.9531 4.03125 21.2344 4.35938C21.6094 4.82812 21.9375 5.34375 22.2656 5.85938L22.5 6.23438C22.7812 6.75 23.0156 7.26562 23.25 7.82812H23.2031ZM12 15.75C13.3125 15.75 14.5312 15.0469 15.2344 13.875C15.8906 12.75 15.8906 11.2969 15.2344 10.125C14.5312 9 13.3125 8.25 12 8.25C10.6406 8.25 9.42188 9 8.71875 10.125C8.0625 11.2969 8.0625 12.75 8.71875 13.875C9.42188 15.0469 10.6406 15.75 12 15.75Z"
            fill="#D1D5DB"
          />
        </svg>
        Manage keywords
      </button>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        className="lg:w-[1000px] lg:pb-8"
      >
        <div className="flex justify-between border-b border-b-gray-200 p-4 lg:p-6">
          <div className="flex flex-col gap-y-1">
            <span className="text-xl font-medium">Keywords explorer</span>
            <span className="text-sm lg:text-base">
              Find the most relevant keyword ideas for your Google Business
              Profile. Based on the Keyword Difficulty and Search Volume
              metrics, decide which of these keywords are your ranking targets
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-2 p-4 lg:flex-row lg:items-center lg:gap-4 lg:px-6">
          <Input
            autoFocus
            type="search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="flex-1 py-2.5 text-sm"
            placeholder="Search Keyword"
          />
          <div className="flex divide-x divide-gray-200">
            <span className="pr-2 text-sm">
              {filteredKeywords.length} keywords found
            </span>
            <span className="pl-2 text-sm text-blue-600">
              {maxKeywords - activeKeywordsCount} targets left
            </span>
          </div>
        </div>
        {keywords && (
          <>
            <KeywordsTable {...commonProps} />
            <KeywordsList {...commonProps} />
          </>
        )}
      </Modal>
    </>
  );
}
