import { ReactNode } from "react";
import ReviewStars from "@/src/components/ReviewStars.tsx";
import { RankedPlace } from "@/src/types.ts";
import PlaceRating from "@/src/components/PlaceRating.tsx";
import { RANKS } from "@/src/constants.ts";
import { getRankName } from "@/src/helpers/utils.ts";

export default function PlacesListItem({ place }: { place?: RankedPlace }) {
  const labelComponents: ReactNode[] = [];

  if (place) {
    labelComponents.push(
      <div
        className="flex items-center gap-x-1 rounded-md bg-blue-50 px-2.5 py-0.5"
        key={place.primary_category}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
        >
          <g clipPath="url(#clip0_1152_1258)">
            <path
              d="M9.96159 3.76119C9.91794 3.61888 9.83671 3.4923 9.72722 3.39598C9.61773 3.29965 9.48442 3.2375 9.34259 3.21664L6.81712 2.83379L5.68764 0.446449C5.62434 0.312455 5.52635 0.19962 5.40476 0.120719C5.28317 0.0418175 5.14284 0 4.99965 0C4.85645 0 4.71612 0.0418175 4.59453 0.120719C4.47294 0.19962 4.37495 0.312455 4.31165 0.446449L3.18217 2.83379L0.656697 3.21664C0.515092 3.23819 0.382083 3.30062 0.272689 3.39686C0.163295 3.49311 0.0818738 3.61934 0.0376199 3.76131C-0.006634 3.90328 -0.0119581 4.05533 0.0222489 4.20029C0.0564558 4.34525 0.128831 4.47734 0.231202 4.58166L2.05918 6.44011L1.62769 9.06373C1.60309 9.21099 1.61857 9.36249 1.67239 9.50103C1.7262 9.63956 1.81619 9.75958 1.93212 9.84743C2.04805 9.93528 2.18527 9.98745 2.3282 9.998C2.47113 10.0086 2.61403 9.97709 2.74067 9.90715L4.99965 8.66836L7.25862 9.90715C7.38531 9.97645 7.52805 10.0074 7.67072 9.99658C7.81339 9.98575 7.95032 9.93353 8.06606 9.84583C8.1818 9.75812 8.27173 9.63842 8.32572 9.50023C8.37971 9.36203 8.3956 9.21085 8.3716 9.06373L7.94011 6.44011L9.76809 4.58114C9.87097 4.47726 9.9437 4.34524 9.97792 4.20021C10.0121 4.05518 10.0065 3.90302 9.96159 3.76119Z"
              fill="#1C64F2"
            />
          </g>
          <defs>
            <clipPath id="clip0_1152_1258">
              <rect width="10" height="10" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <span className="text-nowrap text-xs text-blue-600">
          {place.primary_category}
        </span>
      </div>,
    );
    place.categories.forEach((category) => {
      labelComponents.push(
        <div
          className="flex rounded-md bg-blue-50 px-2.5 py-0.5"
          key={category}
        >
          <span className="text-nowrap text-xs text-blue-600">{category}</span>
        </div>,
      );
    });
  } else {
    for (let i = 0; i < 3; i++) {
      labelComponents.push(
        <div
          key={i}
          className="h-[18px] w-[100px] animate-pulse rounded-md bg-gray-300"
        />,
      );
    }
    labelComponents.push(
      <div
        key={labelComponents.length}
        className="h-[18px] w-[40px] animate-pulse rounded-md bg-gray-300"
      />,
    );
  }
  return (
    <div className="flex flex-col border-b border-b-gray-200 p-4 last:border-b-0">
      <div className="mb-4 flex items-center gap-2">
        {place ? (
          <div
            className={`flex size-7 items-center justify-center rounded-full ${RANKS[getRankName(place.average_rank)].colorClassNames.secondary.bg}`}
          >
            <span
              className={`text-xs font-medium text-green-500 ${RANKS[getRankName(place.average_rank)].colorClassNames.primary.text}`}
            >
              {place.average_rank}
            </span>
          </div>
        ) : (
          <div className="size-7 animate-pulse rounded-full bg-gray-300" />
        )}
        <div className="flex grow flex-col items-start justify-between gap-y-1 lg:flex-row lg:items-center">
          {place ? (
            <span className="text-sm">{place.name}</span>
          ) : (
            <div className="h-[20px] w-[140px] animate-pulse rounded-2xl bg-gray-300" />
          )}
          {place ? (
            <PlaceRating
              ratingCount={place.rating_count}
              variant="small"
              rating={place.rating}
            />
          ) : (
            <ReviewStars isLoading />
          )}
        </div>
      </div>
      <div className="flex flex-wrap gap-2">{labelComponents}</div>
    </div>
  );
}
