import React from "react";
import Card from "@/src/components/Card.tsx";
import { GridPoint, KeywordInfo } from "@/src/types.ts";
import { getRankName } from "@/src/helpers/utils.ts";
import { RANKS } from "@/src/constants.ts";

const stats: {
  label: string;
  valueKey: keyof KeywordInfo;
  renderValue?: (value: number) => React.ReactNode;
}[] = [
  { label: "Keyword Difficulty", valueKey: "difficulty" },
  { label: "Search Volume", valueKey: "volume" },
  { label: "Avg. Rank", valueKey: "average_rank" },
  {
    label: "Rank Change",
    valueKey: "delta_rank",
    renderValue: (value) => {
      return (
        <div className="flex items-center gap-2 lg:gap-3">
          {value !== 0 && (
            <svg
              width="20"
              viewBox="0 0 11 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={value < 0 ? "rotate-180" : ""}
            >
              <path
                className={value > 0 ? "fill-green-500" : "fill-red-500"}
                d="M6.1875 1.3125L10.1875 5.3125C10.4688 5.59375 10.5625 6.03125 10.4062 6.40625C10.25 6.78125 9.875 7.03125 9.5 7.03125H1.5C1.09375 7.03125 0.71875 6.78125 0.5625 6.40625C0.40625 6.03125 0.5 5.59375 0.78125 5.3125L4.78125 1.3125C5.15625 0.90625 5.8125 0.90625 6.1875 1.3125Z"
              />
            </svg>
          )}
          <span className="text-base font-medium lg:text-3xl">
            {Math.abs(value)}
          </span>
        </div>
      );
    },
  },
];
export default function GridArea({
  data,
}: {
  data: { info: KeywordInfo; gridPoints: GridPoint[] } | undefined;
}) {
  const rankingCounts = (data ? data.gridPoints : []).reduce(
    (accumulator, { rank, upgrade }) => {
      if (!upgrade) {
        if (rank) {
          accumulator[getRankName(rank)] += 1;
        } else {
          accumulator.low += 1;
        }
      }
      return accumulator;
    },
    { high: 0, med: 0, low: 0 },
  );

  return (
    <div className="pointer-events-none z-10 flex flex-1 flex-col lg:basis-3/5">
      <Card className="pointer-events-auto grid grid-cols-2 grid-rows-2 px-3 py-2 lg:flex lg:divide-x lg:p-0">
        {stats.map(({ label, valueKey, renderValue }, index) => {
          return (
            <div
              key={index}
              className="flex flex-1 flex-col-reverse items-center justify-center gap-y-1 py-2 lg:flex-col lg:p-6"
            >
              {data ? (
                <>
                  {renderValue ? (
                    renderValue(data.info[valueKey])
                  ) : (
                    <span className="text-base font-medium lg:text-3xl">
                      {data.info[valueKey]}
                    </span>
                  )}
                </>
              ) : (
                <div className="h-[20px] w-[100px] animate-pulse rounded-xl bg-gray-300" />
              )}
              <span className="text-xs lg:text-base">{label}</span>
            </div>
          );
        })}
      </Card>
      <div className="flex flex-1 items-center justify-center">
        {!data && (
          <div className="grid grid-cols-7 grid-rows-7 gap-4">
            {new Array(7 * 7).fill(" ").map((_, index) => {
              return (
                <div
                  className="size-8 h-2 w-2 animate-pulse rounded-full bg-gray-300 lg:size-12"
                  key={index}
                />
              );
            })}
          </div>
        )}
      </div>
      <Card className="pointer-events-auto mt-auto flex-col lg:flex-row">
        {Object.entries(rankingCounts).map(([key, count]) => {
          const { label, colorClassNames } = RANKS[key as keyof typeof RANKS];
          return (
            <div
              key={key}
              className="flex flex-1 items-center border-b border-gray-200 px-4 py-2 last:border-r-0 lg:border-r lg:px-8 lg:py-4"
            >
              <div
                className={`mr-2 flex size-5 items-center justify-center rounded-full ${colorClassNames.secondary.bg}`}
              >
                <div
                  className={`size-3 rounded-full ${colorClassNames.primary.bg}`}
                />
              </div>
              {data ? (
                <>
                  <span className="mr-1 text-sm font-medium">{count}</span>
                  <span className="text-sm">{label}</span>
                </>
              ) : (
                <div className="h-3 w-[140px] animate-pulse rounded-xl bg-gray-300" />
              )}
            </div>
          );
        })}
      </Card>
    </div>
  );
}
