import React from "react";
import { Input as ReactAriaInput, InputProps } from "react-aria-components";
export default function Input({
  className,
  ...rest
}: InputProps & React.RefAttributes<HTMLInputElement>) {
  return (
    <ReactAriaInput
      {...rest}
      className={`block rounded-lg border border-gray-300 bg-white p-3 text-blue-600 placeholder:text-gray-300 focus:border-blue-500 data-[invalid]:border-red-500 data-[invalid]:text-red-500 ${className}`}
    />
  );
}
