import { useQuery } from "@tanstack/react-query";
import { GridPoint, Keyword, KeywordInfo, RankedPlace } from "@/src/types.ts";
import { get } from "@/src/helpers/HTTP.ts";
import dummyData from "@/src/dummyData.ts";

async function getKeywordDetailsRequest(
  placeId: string,
  keywordId: string,
  date: string | undefined,
): Promise<{
  info: KeywordInfo;
  grid: GridPoint[];
  places: RankedPlace[];
}> {
  if (placeId === dummyData.place.id) {
    return dummyData.keywordDetails[keywordId];
  }
  const { info, grid, places } = await get(
    `v1/places/${placeId}/keywords/${keywordId}?date=${date}`,
  );
  return { info, grid, places };
}
export default function useKeywordDetails(
  placeId: string,
  keyword: Keyword | undefined,
  date: string | undefined,
) {
  const keywordId = keyword ? keyword.id : "";
  const {
    data: keywordDetails,
    isLoading: isLoadingKeywordDetails,
    error: keywordDetailsError,
    refetch: refetchKeywordDetails,
  } = useQuery({
    queryKey: ["keywordDetails", placeId, keywordId, date],
    queryFn: () => getKeywordDetailsRequest(placeId, keywordId, date),
    //when failed grids are called they are auto generated
    enabled:
      !!date &&
      !!keyword &&
      (keyword.grid_state === "ready" || keyword.grid_state === "failed"),
  });

  return {
    keywordDetails,
    isLoadingKeywordDetails,
    keywordDetailsError,
    refetchKeywordDetails,
  };
}
