import { useQuery } from "@tanstack/react-query";
import { DetailedPlace } from "@/src/types.ts";
import { get } from "@/src/helpers/HTTP.ts";
import { getQueryKeys } from "@/src/helpers/getQueryKeys.ts";
import dummyData from "@/src/dummyData.ts";

async function getPlaceRequest(placeId: string): Promise<DetailedPlace> {
  if (placeId === dummyData.place.id) {
    return dummyData.detailedPlace;
  }
  const { place } = await get(`v1/places/${placeId}`);
  return place;
}
export default function usePlace(placeId: string) {
  const { data: place, isLoading: isLoadingPlace } = useQuery({
    queryKey: getQueryKeys().places.details(placeId),
    queryFn: () => getPlaceRequest(placeId),
    refetchInterval: ({ state }) => {
      if (state.data) {
        const shouldPoll =
          !state.data.keywords.length ||
          state.data.keywords.find(
            ({ active, grid_state }) =>
              active &&
              (grid_state === "processing" || grid_state === "pending"),
          );
        if (shouldPoll) {
          return 3000;
        }
      }
    },
  });

  return { place, isLoadingPlace };
}
