export const RANKS = {
  high: {
    colorClassNames: {
      primary: {
        text: "text-green-500",
        bg: "bg-green-500",
        bgTransparentHover: "hover:bg-green-500/30",
      },
      secondary: {
        text: "text-green-100",
        bg: "bg-green-100",
      },
    },
    label: "High ranking",
  },
  med: {
    colorClassNames: {
      primary: {
        text: "text-orange-400",
        bg: "bg-orange-400",
        bgTransparentHover: "hover:bg-orange-400/30",
      },
      secondary: {
        text: "text-orange-100",
        bg: "bg-orange-100",
      },
    },
    label: "Medium ranking",
  },
  low: {
    colorClassNames: {
      primary: {
        text: "text-red-500",
        bg: "bg-red-500",
        bgTransparentHover: "hover:bg-red-500/30",
      },
      secondary: {
        text: "text-red-100",
        bg: "bg-red-100",
      },
    },
    label: "Low ranking",
  },
};
export const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
