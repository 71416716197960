import { ReactNode } from "react";

export default function Card({
  children,
  id,
  className,
}: {
  children?: ReactNode;
  id?: string;
  className?: string;
}) {
  return (
    <div className={`flex rounded-lg bg-white shadow ${className}`} id={id}>
      {children}
    </div>
  );
}
