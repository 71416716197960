import { useAppStore } from "@/src/helpers/useAppStore.ts";
import { RANKS } from "@/src/constants.ts";
import { getRankName } from "@/src/helpers/utils.ts";
import { type GridPoint as GridPointType } from "@/src/types.ts";

export default function GridPoint({
  onMouseEnter,
  onMouseLeave,
  upgrade,
  rank,
  isCenterPoint,
}: {
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  isCenterPoint: boolean;
} & Pick<GridPointType, "upgrade" | "rank">) {
  const { colorClassNames } = rank ? RANKS[getRankName(rank)] : RANKS.low;

  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {upgrade ? (
        <button
          className="group flex size-10 items-center justify-center rounded-full transition-[background-color] duration-300 hover:bg-purple-500/30 lg:size-14"
          onClick={() => useAppStore.getState().setIsUpsellModalOpen(true)}
        >
          <div className="flex size-8 items-center justify-center rounded-full border border-dashed border-purple-500 bg-purple-500/30 transition-[background-color] duration-300 group-hover:bg-purple-500 lg:size-12">
            <svg
              viewBox="0 0 18 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 lg:h-5"
            >
              <path
                className="fill-purple-500 transition-[fill] duration-300 group-hover:fill-white"
                d="M0.875 11.75L12.75 0.5L10.4062 8.23438L17.125 9.25L5.25 20.5L7.55469 12.8047L0.875 11.75Z"
              />
            </svg>
          </div>
        </button>
      ) : (
        <div
          className={`flex size-10 cursor-pointer items-center justify-center rounded-full transition-[background-color] duration-300 lg:size-14 ${colorClassNames.primary.bgTransparentHover}`}
        >
          <div
            className={`${colorClassNames.primary.bg} flex size-8 items-center justify-center rounded-full text-base text-white lg:size-12`}
          >
            {isCenterPoint ? (
              <svg
                viewBox="0 0 22 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 lg:h-5"
              >
                <path
                  d="M11.25 0.5L14.6094 6.90625L21.7578 8.15625L16.7188 13.3516L17.7344 20.5L11.25 17.3359L4.72656 20.5L5.78125 13.3516L0.703125 8.15625L7.85156 6.90625L11.25 0.5Z"
                  fill="white"
                />
              </svg>
            ) : (
              <span className="text-xs lg:text-lg">{rank ? rank : "21+"}</span>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
