import { content, plugin } from "flowbite-react/tailwind";
import type { Config } from "tailwindcss";
import tailwindCssAnimate from "tailwindcss-animate";

const config: Config = {
  content: ["./index.html", "./src/**/*.{js,ts,jsx,tsx}", content()],
  theme: {
    extend: {},
  },
  plugins: [tailwindCssAnimate, plugin()],
};
export default config;
