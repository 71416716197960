import usePlaces from "@/src/datahooks/usePlaces.ts";
import dummyData from "@/src/dummyData.ts";
import {
  useNavigate,
  useParams,
  NavigateOptions,
} from "@tanstack/react-router";
import { useAppStore } from "@/src/helpers/useAppStore.ts";
import Button from "@/src/components/base/Button.tsx";
import { useQueryClient } from "@tanstack/react-query";
import { getQueryKeys } from "@/src/helpers/getQueryKeys.ts";
import { DetailedPlace } from "@/src/types.ts";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "@/tailwind.config.ts";

import IconAngleDown from "fontawesome/solid/angle-down.svg?react";
import IconAngleUp from "fontawesome/solid/angle-up.svg?react";
import { useState } from "react";
import Tooltip from "../base/Tooltip";

const {
  theme: { screens },
} = resolveConfig(tailwindConfig);

let displayedPlaces = 200;

if (window.innerWidth > Number(screens.lg.replace("px", ""))) {
  displayedPlaces = 2;
}
if (window.innerWidth > Number(screens.xl.replace("px", ""))) {
  displayedPlaces = 6;
}

export default function PlaceSelection() {
  const { places } = usePlaces();
  const { placeId } = useParams({ strict: false });
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const pageCount = places ? Math.ceil(places.length / displayedPlaces) : 0;

  return (
    <div className="flex flex-col pb-4 lg:items-center">
      <div className="flex flex-col lg:mb-4 lg:items-center lg:gap-y-4">
        {places && places.length > displayedPlaces && page !== 0 && (
          <Button
            onPress={() => setPage(page - 1)}
            className="group flex size-8 items-center justify-center rounded-full border border-gray-300 bg-white text-2xl text-gray-300 data-[hovered]:bg-gray-300 data-[hovered]:text-white lg:size-6"
          >
            <IconAngleUp className="h-3 fill-gray-300 group-data-[hovered]:fill-white" />
          </Button>
        )}
        {places &&
          (places.length
            ? places.slice(
                page * displayedPlaces,
                page * displayedPlaces + displayedPlaces,
              )
            : [dummyData.place]
          ).map(({ name, id }) => {
            const isSelected = placeId === id;

            return (
              <Tooltip
                key={id}
                placement="right"
                renderTriggerElement={(props) => (
                  <Button
                    {...props}
                    onPress={() => {
                      const navigateOptions: NavigateOptions = {
                        to: "/$placeId/home",
                        params: { placeId: id },
                      };
                      const existingPlace =
                        queryClient.getQueryData<DetailedPlace>(
                          getQueryKeys().places.details(id),
                        );
                      if (existingPlace) {
                        const activeKeyword = existingPlace.keywords.find(
                          ({ active, grid_state }) =>
                            (active && grid_state === "failed") ||
                            grid_state === "ready",
                        );
                        if (activeKeyword) {
                          navigateOptions.search = {
                            selectedKeywordId: activeKeyword.id,
                            date: activeKeyword.snapshot_dates[0],
                          };
                        }
                      }
                      navigate(navigateOptions);
                    }}
                    className={`flex items-center gap-x-2 p-4 lg:rounded-full lg:border-2 lg:p-1 ${isSelected ? "border-blue-600 bg-blue-50 lg:bg-transparent" : "border-transparent data-[hovered]:border-blue-600"}`}
                  >
                    <div className="flex size-8 shrink-0 items-center justify-center rounded-full bg-orange-400 text-white lg:size-10 lg:text-lg">
                      {name[0]}
                    </div>
                    <span className="truncate text-sm font-medium lg:hidden">
                      {name}
                    </span>
                  </Button>
                )}
              >
                {name}
              </Tooltip>
            );
          })}
        {places && places.length > displayedPlaces && page < pageCount - 1 && (
          <Button
            onPress={() => setPage(page + 1)}
            className="group flex size-8 items-center justify-center rounded-full border border-gray-300 bg-white text-2xl text-gray-300 data-[hovered]:bg-gray-300 data-[hovered]:text-white lg:size-6"
          >
            <IconAngleDown className="h-3 fill-gray-300 group-data-[hovered]:fill-white" />
          </Button>
        )}
      </div>
      <Button
        onPress={() => {
          useAppStore.getState().setIsAddModalOpen(true);
        }}
        className="group flex items-center gap-x-2 rounded-full border-transparent p-4 data-[hovered]:border-blue-600 lg:border-2 lg:p-1"
      >
        <div className="flex size-8 items-center justify-center rounded-full border border-gray-300 bg-white text-2xl text-gray-300 group-data-[hovered]:bg-gray-300 group-data-[hovered]:text-white lg:size-10">
          +
        </div>
        <span className="text-sm font-medium lg:hidden">Add an Account</span>
      </Button>
    </div>
  );
}
