import { useState } from "react";
import { BasePlace, Coordinates, Place } from "@/src/types.ts";
import usePlacesSearch from "@/src/datahooks/usePlacesSearch.tsx";
import { ComboBox, ListBox, ListBoxItem } from "react-aria-components";
import Input from "@/src/components/base/Input.tsx";
import Popover from "@/src/components/base/Popover.tsx";
import { useDebounce } from "@/src/helpers/utils.ts";
export default function PlacesSearchBox({
  selectPlace,
  location,
}: {
  selectPlace: (place: BasePlace) => void;
  location: Coordinates | undefined;
}) {
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 300);
  const { places } = usePlacesSearch(debouncedSearchText, location);

  let content = (
    <ListBox<Place> className="max-h-80 overflow-y-auto">
      {(place) => (
        <ListBoxItem
          key={place.id}
          id={place.id}
          className="flex flex-col border-b border-b-gray-300 p-4 last:border-b-0 hover:bg-gray-100"
        >
          <span className="font-medium">{place.name}</span>
          <span className="text-xs">{place.address}</span>
        </ListBoxItem>
      )}
    </ListBox>
  );
  if (places && !places.length && searchText) {
    content = (
      <div className="flex h-40 items-center justify-center">
        No results found
      </div>
    );
  }

  return (
    <ComboBox
      autoFocus
      items={places}
      allowsEmptyCollection
      allowsCustomValue
      inputValue={searchText}
      onInputChange={(text) => setSearchText(text)}
      onSelectionChange={(selectedId) => {
        if (places) {
          const targetPlace = places.find(({ id }) => id === selectedId);
          if (targetPlace) {
            selectPlace(targetPlace);
          }
        }
      }}
    >
      <Input
        type="search"
        className="w-full"
        placeholder="Search for your Google Business Profile here..."
      />
      <Popover className="w-[--trigger-width]" zIndex={40}>
        {content}
      </Popover>
    </ComboBox>
  );
}
