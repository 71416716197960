import React, { useEffect, useRef } from "react";
import {
  Dialog,
  Modal as AriaModal,
  ModalOverlay,
  Button,
} from "react-aria-components";

import XMarkIcon from "fontawesome/solid/xmark.svg?react";

export default function Modal({
  isOpen,
  onClose,
  onAfterClose,
  children,
  className,
}: {
  isOpen: boolean;
  onClose?: () => void;
  onAfterClose?: () => void;
  children: React.ReactNode;
  className: string;
}) {
  const timeoutRef = useRef<number>();

  useEffect(() => {
    if (!isOpen) {
      if (onAfterClose) {
        timeoutRef.current = window.setTimeout(onAfterClose, 300);
      }
    } else {
      clearTimeout(timeoutRef.current);
    }
  }, [isOpen]);

  return (
    <ModalOverlay
      className={({ isExiting, isEntering }) =>
        `fixed inset-0 z-30 flex w-screen bg-gray-900/20 backdrop-blur-sm ${isExiting && "duration-300 animate-out fade-out"} ${isEntering && "duration-300 animate-in fade-in"} lg:items-center lg:justify-center`
      }
      isDismissable={!!onClose}
      isOpen={isOpen}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          onClose && onClose();
        }
      }}
    >
      <AriaModal
        className={({ isExiting, isEntering }) =>
          `w-full bg-white shadow lg:size-fit lg:rounded-lg ${isEntering && `duration-300 animate-in lg:zoom-in-90`} ${isExiting && `duration-300 animate-out lg:zoom-out-90`}`
        }
      >
        <Dialog className={`relative flex h-full flex-col ${className}`}>
          {({ close }) => (
            <>
              {onClose && (
                <Button
                  onPress={close}
                  className="group absolute right-4 top-4 lg:right-6 lg:top-6"
                >
                  <XMarkIcon className="w-4 fill-gray-200 group-hover:fill-blue-800 lg:w-5" />
                </Button>
              )}
              {children}
            </>
          )}
        </Dialog>
      </AriaModal>
    </ModalOverlay>
  );
}
