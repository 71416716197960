import { JSX } from "react";
import useKeywordsMutations from "@/src/datahooks/useKeywordsMutations.tsx";

export default function AddKeyword({
  keywordLabel,
  renderTrigger,
}: {
  keywordLabel: string;
  renderTrigger: (addKeyword: () => void, isLoading: boolean) => JSX.Element;
}) {
  const { addKeyword, isAddingKeyword } = useKeywordsMutations();

  function onClick() {
    addKeyword({ label: keywordLabel });
  }
  return renderTrigger(onClick, isAddingKeyword);
}
