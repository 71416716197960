import { createFileRoute } from "@tanstack/react-router";
import "mapbox-gl/dist/mapbox-gl.css";
import GridArea from "@/src/components/home/GridArea.tsx";
import PlacesList from "@/src/components/home/PlacesList/PlacesList.tsx";
import BannerCard from "@/src/components/BannerCard.tsx";
import usePlace from "@/src/datahooks/usePlace.tsx";
import Keywords from "@/src/components/home/Keywords.tsx";
import Header from "@/src/components/layout/Header.tsx";
import useKeywordDetails from "@/src/datahooks/useKeywordDetails.tsx";
import GridMap from "@/src/components/home/GridMap/GridMap.tsx";
import Card from "@/src/components/Card.tsx";
import DateSelector from "@/src/components/home/DateSelector.tsx";
import { useSelectedPlace } from "@/src/contexts.ts";
import ErrorDialog from "@/src/components/home/ErrorDialog.tsx";
import { Route as PlaceIdRoute } from "@/src/routes/$placeId/_layout.tsx";
import DrivesCount from "@/src/components/home/DrivesCount";

import LocationIcon from "fontawesome/solid/location-dot.svg?react";

type SearchParams = { selectedKeywordId?: string; date?: string };
export const Route = createFileRoute("/$placeId/_layout/home")({
  component: Home,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const searchObject: SearchParams = {};
    if (search.selectedKeywordId) {
      searchObject.selectedKeywordId = search.selectedKeywordId as string;
    }
    if (search.date) {
      searchObject.date = search.date as string;
    }
    return searchObject;
  },
});

function Home() {
  const { selectedPlace } = useSelectedPlace();
  const { selectedKeywordId, date } = Route.useSearch();
  const { placeId } = PlaceIdRoute.useParams();
  const { place } = usePlace(placeId);

  const keyword =
    place && place.keywords.find(({ id }) => id === selectedKeywordId);

  const { keywordDetails, keywordDetailsError, refetchKeywordDetails } =
    useKeywordDetails(placeId, keyword, date);

  const datePickerComponent = place && (
    <DateSelector
      selectedDate={date}
      availableDates={keyword ? keyword.snapshot_dates : []}
    />
  );
  const listComponent = (
    <PlacesList places={keywordDetails && keywordDetails.places} />
  );
  return (
    <>
      <Header label="Growth">
        <div className="hidden items-center lg:flex">{datePickerComponent}</div>
      </Header>
      <BannerCard>
        <div className="flex shrink-0 flex-col items-center gap-y-2">
          {place && (
            <DrivesCount
              drives={place.drives}
              drivesRemaining={place.drives_remaining}
            />
          )}
          <div className="hidden items-center space-x-2 text-gray-400 md:flex">
            <LocationIcon className="w-3 fill-gray-400" />
            {place && (
              <span className="text-gray-400">
                {place.city && `${place.city}, `}
                {place.country}
              </span>
            )}
          </div>
        </div>
      </BannerCard>
      <div className="lg:hidden">{datePickerComponent}</div>
      <Keywords
        data={
          place && {
            keywords: place.keywords,
            maxKeywords: place.subscription.item.max_keywords,
          }
        }
        selectedKeywordId={selectedKeywordId}
        isDisabled={selectedPlace.subscription.status === "cancelled"}
      />
      <div className="relative mx-[-16px] flex min-h-[80vh] flex-1 gap-x-4 overflow-hidden p-5 lg:mx-0 lg:rounded-lg">
        <GridMap gridPoints={keywordDetails && keywordDetails.grid} />
        <GridArea
          data={
            keywordDetails && {
              info: keywordDetails.info,
              gridPoints: keywordDetails.grid,
            }
          }
        />
        <Card className="z-10 hidden flex-col self-start lg:flex lg:basis-2/5">
          {listComponent}
        </Card>
        <ErrorDialog
          isOpen={!!keywordDetailsError}
          onRetry={refetchKeywordDetails}
        />
      </div>
      <Card className="flex-col lg:hidden">{listComponent}</Card>
    </>
  );
}
