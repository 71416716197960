import { useQuery } from "@tanstack/react-query";
import { Customer } from "@/src/types.ts";
import { get } from "@/src/helpers/HTTP.ts";

async function getCustomerRequest(): Promise<Customer> {
  const { customer } = await get(`v1/customers`);
  return customer;
}
export default function useCustomer() {
  const { data: customer, isLoading: isLoadingCustomer } = useQuery({
    queryKey: ["customer"],
    queryFn: () => getCustomerRequest(),
    refetchOnMount: false,
  });
  return { customer, isLoadingCustomer };
}
