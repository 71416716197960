import Modal from "@/src/components/base/Modal.tsx";
import usePlaceMutations from "@/src/datahooks/usePlaceMutations.tsx";
import useChargebeeUpdates from "@/src/datahooks/useChargebeeUpdates.tsx";
import Logo from "@/src/assets/logo.svg?react";
import { useAppStore } from "@/src/helpers/useAppStore.ts";
import { useSelectedPlace } from "@/src/contexts.ts";
import { openChargebeeHostedPage } from "@/src/helpers/utils.ts";

export default function ReactivateSubscriptionModal() {
  const { selectedPlace } = useSelectedPlace();
  const { reactivatePlace } = usePlaceMutations();
  const { onSubscriptionStatusChange } = useChargebeeUpdates();
  const isOpen = useAppStore((state) => state.isReactivateModalOpen);

  function onReactivate() {
    reactivatePlace({
      placeId: selectedPlace.id,
    }).then((hostedPage) => {
      openChargebeeHostedPage(hostedPage, async () => {
        onSubscriptionStatusChange(selectedPlace.subscription.id, "active");
        useAppStore.getState().setIsReactivateModalOpen(false);
      });
    });
  }

  return (
    <Modal
      className="w-[680px] items-center px-12 py-8"
      isOpen={isOpen}
      onClose={() => useAppStore.getState().setIsReactivateModalOpen(false)}
    >
      <Logo className="mb-4" />
      <span className="mb-2 text-2xl font-medium">
        Your subscription is currently inactive
      </span>
      <span className="mb-6 text-center">
        To continue ranking your business in the local search, you should
        reactivate your subscription! Feel free to contact our support team for
        any issues or additional questions
      </span>
      <button
        className="w-3/4 flex-1 rounded-lg bg-blue-600 py-4 font-medium text-white"
        onClick={onReactivate}
      >
        Reactivate subscription
      </button>
    </Modal>
  );
}
