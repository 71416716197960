import PlacesSearchBox from "@/src/components/layout/AddPlaceModal/PlacesSearchBox.tsx";
import usePlaceMutations from "@/src/datahooks/usePlaceMutations.tsx";
import { openChargebeeHostedPage } from "@/src/helpers/utils.ts";
import { Button } from "react-aria-components";
import PlaceRating from "@/src/components/PlaceRating.tsx";
import { BasePlace, Coordinates } from "@/src/types.ts";
import { HTTPError } from "@/src/helpers/HTTP.ts";
import { useEffect, useState } from "react";
import { useAppStore } from "@/src/helpers/useAppStore.ts";

export default function PlaceSelection({
  selectedPlace,
  setSelectedPlace,
  onChargebeeSuccess,
  onChargebeeClose,
  onChargebeeOpen,
}: {
  selectedPlace: BasePlace | null;
  setSelectedPlace: (place: BasePlace | null) => void;
  onChargebeeSuccess: () => void;
  onChargebeeClose: () => void;
  onChargebeeOpen: () => void;
}) {
  const [location, setLocation] = useState<Coordinates | undefined>();
  const geoState = useAppStore((state) => state.geoState);
  const [isDuplicateError, setIsDuplicateError] = useState(false);
  const { addPlace } = usePlaceMutations();

  function updateLocation() {
    navigator.geolocation.getCurrentPosition(
      (location) => {
        useAppStore.getState().setGeoState("granted");
        setLocation({
          longitude: location.coords.longitude,
          latitude: location.coords.latitude,
        });
      },
      (positionError) => {
        if (positionError.PERMISSION_DENIED) {
          useAppStore.getState().setGeoState("denied");
        }
      },
    );
  }

  useEffect(() => {
    if (geoState === "granted") {
      updateLocation();
    }
  }, []);

  function onConfirm() {
    if (selectedPlace) {
      addPlace({
        placeId: selectedPlace.id,
        placeName: selectedPlace.name,
      })
        .then((hostedPage) => {
          onChargebeeOpen();
          openChargebeeHostedPage(
            hostedPage,
            onChargebeeSuccess,
            onChargebeeClose,
          );
        })
        .catch((error) => {
          if (error instanceof HTTPError && error.code === 409) {
            setIsDuplicateError(true);
          }
        });
    }
  }
  return (
    <>
      <span className="mb-2 text-center text-xl font-medium lg:text-2xl">
        Let&apos;s find your Google Business Profile
      </span>
      <span className="mb-6 text-center text-sm lg:text-base">
        Select the location you want to get ranked higher in the Google local
        results
      </span>
      <div className="flex flex-col lg:w-3/4">
        {selectedPlace ? (
          <>
            <div className="flex items-center rounded-lg bg-white p-4 shadow lg:bg-gray-50 lg:p-6 lg:shadow-none">
              <div className="mr-2 flex size-16 shrink-0 items-center justify-center rounded-full bg-orange-400 text-4xl text-white lg:mr-6 lg:size-20 lg:text-5xl">
                {selectedPlace.name[0]}
              </div>
              <div className="flex flex-col">
                <PlaceRating
                  rating={selectedPlace.rating}
                  ratingCount={selectedPlace.rating_count}
                  variant="large"
                />
                <span className="text-xl font-medium lg:text-3xl lg:font-bold">
                  {selectedPlace.name}
                </span>
                <span className="text-sm lg:text-base">
                  {selectedPlace.address}
                </span>
              </div>
            </div>
            {isDuplicateError && (
              <span className="mt-4 text-center text-sm text-red-600">
                This GBP is already registered. Please check if you selected the
                right one, or contact support in case there is an issue
              </span>
            )}
            <div className="mt-6 flex w-full flex-col-reverse gap-4 lg:flex-row">
              <Button
                className="flex-1 rounded-lg bg-gray-200 py-3 font-medium text-gray-400 lg:bg-transparent lg:py-4 "
                onPress={() => {
                  setSelectedPlace(null);
                  setIsDuplicateError(false);
                }}
              >
                That&apos;s not me
              </Button>
              <Button
                className="flex-1 rounded-lg bg-blue-600 py-3 font-medium text-white data-[disabled]:opacity-40 lg:py-4"
                onPress={onConfirm}
                isDisabled={isDuplicateError}
              >
                Confirm
              </Button>
            </div>
          </>
        ) : (
          <>
            <PlacesSearchBox
              selectPlace={(place) => setSelectedPlace(place)}
              location={location}
            />
            {navigator.geolocation && geoState !== "granted" && (
              <span className="mt-4 text-center text-sm text-gray-400">
                {geoState === "denied" ? (
                  "You can "
                ) : (
                  <>
                    <Button className="text-blue-500" onPress={updateLocation}>
                      Click here
                    </Button>
                    {" to "}
                  </>
                )}
                improve your search results by allowing location services in
                your browser.
              </span>
            )}
          </>
        )}
      </div>
    </>
  );
}
