import {
  ButtonProps,
  OverlayArrow,
  TooltipTrigger,
  Tooltip as AriaTooltip,
  PopoverProps as AriaPopoverProps,
} from "react-aria-components";
import React, { JSX, useState } from "react";
interface PopoverProps extends Omit<AriaPopoverProps, "children"> {
  children: React.ReactNode;
  isDisabled?: boolean;
  renderTriggerElement: (props: ButtonProps) => JSX.Element;
  placement: "top" | "right";
}
export default function Tooltip({
  children,
  isDisabled = false,
  renderTriggerElement,
  placement,
}: PopoverProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <TooltipTrigger isOpen={isOpen && !isDisabled}>
      {renderTriggerElement({
        onHoverChange: (isHovering) => {
          setIsOpen(isHovering);
        },
      })}
      <AriaTooltip
        placement={placement}
        offset={10}
        className="rounded-lg bg-white p-3 text-sm shadow data-[entering]:animate-in data-[exiting]:animate-out data-[entering]:fade-in data-[exiting]:fade-out"
      >
        <OverlayArrow className="group">
          <svg
            width={12}
            height={12}
            viewBox="0 0 12 12"
            className="group-data-[placement=right]:rotate-90"
          >
            <path d="M0 0 L6 6 L12 0" fill="white" className="drop-shadow" />
          </svg>
        </OverlayArrow>
        {children}
      </AriaTooltip>
    </TooltipTrigger>
  );
}
