import React, { useState } from "react";
import { Button, FieldError, Form, TextField } from "react-aria-components";
import Modal from "@/src/components/base/Modal.tsx";
import Input from "@/src/components/base/Input.tsx";

import ShieldIcon from "fontawesome/solid/shield.svg?react";
import useCustomerMutations from "@/src/datahooks/useCustomerMutations.tsx";

export default function ChangePasswordModal() {
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState("");
  const { updatePassword, isUpdatingPassword } = useCustomerMutations();

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const data = Object.fromEntries(new FormData(e.currentTarget));
    const { password, confirmPassword } = data;
    if (password === confirmPassword && typeof password === "string") {
      updatePassword({ password: password }).then(() => {
        setIsOpen(false);
      });
    } else {
      setError("Passwords don't match");
    }
  }

  return (
    <>
      <Button
        className="flex items-center gap-x-2 px-4 py-6 text-sm font-medium text-gray-900 hover:bg-gray-100 lg:gap-x-2.5 lg:py-2.5 lg:font-normal"
        onPress={() => setIsOpen(true)}
      >
        <ShieldIcon className="w-4" />
        Change password
      </Button>
      <Modal
        isOpen={isOpen}
        className="px-6 py-12 lg:w-[680px]"
        onClose={() => {
          if (!isUpdatingPassword) {
            setIsOpen(false);
          }
        }}
      >
        <span className="mb-6 text-center text-2xl font-medium">
          Change Password
        </span>
        <Form
          onSubmit={onSubmit}
          className="flex flex-col gap-y-6 lg:mx-auto lg:w-3/4"
          validationErrors={
            error ? { confirmPassword: error, password: error } : undefined
          }
        >
          <TextField name="password" type="password" isRequired autoFocus>
            <Input placeholder="New Password" className="w-full" />
            <FieldError className="text-xs text-red-500" />
          </TextField>
          <TextField name="confirmPassword" type="password" isRequired>
            <Input placeholder="Confirm New Password" className="w-full" />
            <FieldError className="text-xs text-red-500" />
          </TextField>
          <button className="w-full rounded-lg bg-blue-600 py-3 font-medium text-white">
            Update
          </button>
        </Form>
      </Modal>
    </>
  );
}
