import { forwardRef } from "react";
import { Popover as AriaPopover, PopoverProps } from "react-aria-components";

const Popover = forwardRef<HTMLDivElement, { zIndex?: number } & PopoverProps>(
  ({ children, className, zIndex = 20, ...rest }, ref) => {
    return (
      <AriaPopover
        {...rest}
        style={{ zIndex }}
        ref={ref}
        className={`overflow-hidden rounded-lg bg-white shadow data-[entering]:duration-300 data-[exiting]:duration-300 data-[entering]:animate-in data-[exiting]:animate-out data-[entering]:fade-in data-[exiting]:fade-out ${className}`}
      >
        {children}
      </AriaPopover>
    );
  },
);
Popover.displayName = "Popover";
export default Popover;
