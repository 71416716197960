import { createContext, useContext } from "react";
import { Place } from "@/src/types";
import dummyData from "@/src/dummyData";
import { useParams } from "@tanstack/react-router";

const SelectedPlaceContext = createContext<Place | undefined>(undefined);

export const SelectedPlaceProvider = SelectedPlaceContext.Provider;
export function useSelectedPlace() {
  const selectedPlace = useContext(SelectedPlaceContext);
  const { placeId } = useParams({ strict: false });
  const isDummyPlace = placeId === dummyData.place.id;

  if (selectedPlace === undefined) {
    throw new Error(
      "Selected place hook must be used inside a SelectedPlaceContext",
    );
  }
  return { selectedPlace, isDummyPlace };
}
