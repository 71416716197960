import { useMutation, useQueryClient } from "@tanstack/react-query";
import { del, put, post } from "@/src/helpers/HTTP.ts";
import { getQueryKeys } from "@/src/helpers/getQueryKeys.ts";
import { produce } from "immer";
import { DetailedPlace, Keyword } from "@/src/types.ts";
import { Route } from "@/src/routes/$placeId/_layout.tsx";
import dummyData from "@/src/dummyData.ts";

async function enableKeywordRequest(placeId: string, keywordId: string) {
  if (placeId === dummyData.place.id) {
    return;
  }
  await put(`v1/places/${placeId}/keywords/${keywordId}`);
}
async function disableKeywordRequest(placeId: string, keywordId: string) {
  if (placeId === dummyData.place.id) {
    return;
  }
  await del(`v1/places/${placeId}/keywords/${keywordId}`);
}
async function addKeywordRequest(
  placeId: string,
  label: string,
): Promise<Keyword> {
  const { keyword } = await post(`v1/places/${placeId}/keywords`, {
    label,
  });
  return keyword;
}

export default function useKeywordsMutations() {
  const { placeId } = Route.useParams();
  const queryClient = useQueryClient();

  const { mutateAsync: toggleKeyword, isPending: isTogglingKeyword } =
    useMutation({
      mutationFn: ({
        keywordId,
        isActive,
      }: {
        keywordId: string;
        isActive: boolean;
      }) =>
        isActive
          ? enableKeywordRequest(placeId, keywordId)
          : disableKeywordRequest(placeId, keywordId),
      onSuccess: (_, { keywordId, isActive }) => {
        queryClient.setQueryData<DetailedPlace>(
          getQueryKeys().places.details(placeId),
          (detailedPlace) => {
            if (detailedPlace) {
              return produce(detailedPlace, (draftDetailedPlace) => {
                for (
                  let i = 0;
                  i < draftDetailedPlace.keywords.length;
                  i += 1
                ) {
                  if (draftDetailedPlace.keywords[i].id === keywordId) {
                    draftDetailedPlace.keywords[i].active = isActive;
                  }
                }
              });
            }
          },
        );
      },
    });

  const { mutateAsync: addKeyword, isPending: isAddingKeyword } = useMutation({
    mutationFn: ({ label }: { label: string }) =>
      addKeywordRequest(placeId, label),
    onSuccess: (keyword) => {
      queryClient.setQueryData<DetailedPlace>(
        getQueryKeys().places.details(placeId),
        (detailedPlace) => {
          if (detailedPlace) {
            return produce(detailedPlace, (draftDetailedPlace) => {
              draftDetailedPlace.keywords.push(keyword);
            });
          }
        },
      );
    },
  });

  return {
    toggleKeyword,
    isTogglingKeyword,
    addKeyword,
    isAddingKeyword,
  };
}
