import {
  Coordinates,
  DetailedPlace,
  GridPoint,
  Keyword,
  KeywordInfo,
  Place,
  RankedPlace,
  Reviews,
} from "@/src/types";

const place: Place = {
  name: "Moe's tavern",
  id: "thisisnotarealid",
  rating_count: "257",
  rating: 4,
  address: "74 Walnut Street",
  subscription: { status: "active", id: "testid" },
  location: {
    latitude: 40.7654454,
    longitude: -73.9805358,
  },
};
const grid = [
  {
    latitude: 40.76949222400905,
    longitude: -73.98587908762754,
    rank: 8,
    places: [
      {
        name: "Joe's Bar & Grill",
        rank: 1,
      },
      {
        name: "The Lucky Leprechaun",
        rank: 2,
      },
      {
        name: "The Urban Bar",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: 8,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.76949221031625,
    longitude: -73.9840978832838,
    rank: 8,
    places: [
      {
        name: "Joe's Bar & Grill",
        rank: 1,
      },
      {
        name: "Barney's Pub",
        rank: 2,
      },
      {
        name: "The Drunken Clam",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: 8,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.76949219662346,
    longitude: -73.98231667894042,
    rank: 5,
    places: [
      {
        name: "Barney's Pub",
        rank: 1,
      },
      {
        name: "Joe's Bar & Grill",
        rank: 2,
      },
      {
        name: "The Drunken Clam",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: 5,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.76949218293066,
    longitude: -73.9805354745974,
    rank: 2,
    places: [
      {
        name: "Joe's Bar & Grill",
        rank: 1,
      },
      {
        name: "Moe's tavern",
        rank: 2,
        owner: true,
      },
      {
        name: "The Drunken Clam",
        rank: 3,
      },
    ],
  },
  {
    latitude: 40.769492169237864,
    longitude: -73.97875427025475,
    rank: 1,
    places: [
      {
        name: "Moe's tavern",
        rank: 1,
        owner: true,
      },
      {
        name: "The Drunken Clam",
        rank: 2,
      },
      {
        name: "The Barrel Room",
        rank: 3,
      },
    ],
  },
  {
    latitude: 40.769492155545066,
    longitude: -73.97697306591247,
    rank: 1,
    places: [
      {
        name: "Moe's tavern",
        rank: 1,
        owner: true,
      },
      {
        name: "The Barrel Room",
        rank: 2,
      },
      {
        name: "The Drunken Clam",
        rank: 3,
      },
    ],
  },
  {
    latitude: 40.76949214185227,
    longitude: -73.97519186157055,
    rank: 2,
    places: [
      {
        name: "The Barrel Room",
        rank: 1,
      },
      {
        name: "Moe's tavern",
        rank: 2,
        owner: true,
      },
      {
        name: "The Drunken Clam",
        rank: 3,
      },
    ],
  },
  {
    latitude: 40.768142666526174,
    longitude: -73.98587887061524,
    rank: 9,
    places: [
      {
        name: "Joe's Bar & Grill",
        rank: 1,
      },
      {
        name: "Barney's Pub",
        rank: 2,
      },
      {
        name: "The Hoppy Place",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: 9,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.76814265283404,
    longitude: -73.98409770244578,
    rank: 8,
    places: [
      {
        name: "Joe's Bar & Grill",
        rank: 1,
      },
      {
        name: "Barney's Pub",
        rank: 2,
      },
      {
        name: "The Golden Tap",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: 8,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.768142639141885,
    longitude: -73.98231653427668,
    rank: 4,
    places: [
      {
        name: "Joe's Bar & Grill",
        rank: 1,
      },
      {
        name: "The Drunken Clam",
        rank: 2,
      },
      {
        name: "Barney's Pub",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: 4,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.76814262544974,
    longitude: -73.98053536610794,
    rank: 3,
    places: [
      {
        name: "Marea",
        rank: 1,
      },
      {
        name: "The Drunken Clam",
        rank: 2,
      },
      {
        name: "Moe's tavern",
        rank: 3,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.76814261175759,
    longitude: -73.97875419793958,
    rank: 1,
    places: [
      {
        name: "Moe's tavern",
        rank: 1,
        owner: true,
      },
      {
        name: "The Drunken Clam",
        rank: 2,
      },
      {
        name: "The Barrel Room",
        rank: 3,
      },
    ],
  },
  {
    latitude: 40.76814259806544,
    longitude: -73.97697302977159,
    rank: 2,
    places: [
      {
        name: "The Barrel Room",
        rank: 1,
      },
      {
        name: "Moe's tavern",
        rank: 2,
        owner: true,
      },
      {
        name: "The Drunken Clam",
        rank: 3,
      },
    ],
  },
  {
    latitude: 40.76814258437329,
    longitude: -73.97519186160396,
    rank: 2,
    places: [
      {
        name: "The Barrel Room",
        rank: 1,
      },
      {
        name: "Moe's tavern",
        rank: 2,
        owner: true,
      },
      {
        name: "Pazza Notte",
        rank: 3,
      },
    ],
  },
  {
    latitude: 40.76679310907065,
    longitude: -73.98587865361766,
    rank: 11,
    places: [
      {
        name: "The Hoppy Place",
        rank: 1,
      },
      {
        name: "The Red Door Pub",
        rank: 2,
      },
      {
        name: "Joe's Bar & Grill",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: 11,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.76679309537916,
    longitude: -73.98409752162003,
    rank: 7,
    places: [
      {
        name: "The Drunken Clam",
        rank: 1,
      },
      {
        name: "The Cozy Corner Pub",
        rank: 2,
      },
      {
        name: "Joe G Pizza & Restaurant",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: 7,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.766793081687666,
    longitude: -73.98231638962277,
    rank: 5,
    places: [
      {
        name: "The Drunken Clam",
        rank: 1,
      },
      {
        name: "The Cozy Corner Pub",
        rank: 2,
      },
      {
        name: "Joe G Pizza & Restaurant",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: 5,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.766793067996176,
    longitude: -73.98053525762586,
    rank: 1,
    places: [
      {
        name: "Moe's tavern",
        rank: 1,
        owner: true,
      },
      {
        name: "The Drunken Clam",
        rank: 2,
      },
      {
        name: "The Lucky Leprechaun",
        rank: 3,
      },
    ],
  },
  {
    latitude: 40.766793054304685,
    longitude: -73.97875412562932,
    rank: 1,
    places: [
      {
        name: "Moe's tavern",
        rank: 1,
        owner: true,
      },
      {
        name: "The Drunken Clam",
        rank: 2,
      },
      {
        name: "The Barrel Room",
        rank: 3,
      },
    ],
  },
  {
    latitude: 40.766793040613194,
    longitude: -73.97697299363315,
    rank: 2,
    places: [
      {
        name: "The Barrel Room",
        rank: 1,
      },
      {
        name: "Moe's tavern",
        rank: 2,
        owner: true,
      },
      {
        name: "The Crafty Brewer",
        rank: 3,
      },
    ],
  },
  {
    latitude: 40.766793026921704,
    longitude: -73.97519186163736,
    rank: 4,
    places: [
      {
        name: "The Barrel Room",
        rank: 1,
      },
      {
        name: "Gabriel's Bar & Restaurant",
        rank: 2,
      },
      {
        name: "The Crafty Brewer",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: 4,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.765443551642534,
    longitude: -73.98587843663483,
    rank: 12,
    places: [
      {
        name: "The Hoppy Place",
        rank: 1,
      },
      {
        name: "The Silver Stein",
        rank: 2,
      },
      {
        name: "The Red Door Pub",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: 12,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.765443537951704,
    longitude: -73.98409734080657,
    rank: 8,
    places: [
      {
        name: "The Cozy Corner Pub",
        rank: 1,
      },
      {
        name: "The Drunken Clam",
        rank: 2,
      },
      {
        name: "The Silver Stein",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: 8,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.76544352426087,
    longitude: -73.98231624497868,
    rank: 5,
    places: [
      {
        name: "The Drunken Clam",
        rank: 1,
      },
      {
        name: "Joe G Pizza & Restaurant",
        rank: 2,
      },
      {
        name: "The Cozy Corner Pub",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: 5,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.7654454,
    longitude: -73.9805358,
    rank: 1,
    places: [
      {
        name: "Moe's tavern",
        rank: 1,
        owner: true,
      },
      {
        name: "The Drunken Clam",
        rank: 2,
      },
      {
        name: "The Green Dragon",
        rank: 3,
      },
    ],
  },
  {
    latitude: 40.765445386309146,
    longitude: -73.9787547041222,
    rank: 1,
    places: [
      {
        name: "Moe's tavern",
        rank: 1,
        owner: true,
      },
      {
        name: "The Barrel Room",
        rank: 2,
      },
      {
        name: "Angelo's Coal Oven Pizzeria",
        rank: 3,
      },
    ],
  },
  {
    latitude: 40.7654453726183,
    longitude: -73.97697360824476,
    rank: 6,
    places: [
      {
        name: "The Barrel Room",
        rank: 1,
      },
      {
        name: "Mangia 57th - Midtown Italian Food & Corporate Catering NYC",
        rank: 2,
      },
      {
        name: "Gabriel's Bar & Restaurant",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: 6,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.76544535892746,
    longitude: -73.9751925123677,
    rank: 9,
    places: [
      {
        name: "Gabriel's Bar & Restaurant",
        rank: 1,
      },
      {
        name: "The Barrel Room",
        rank: 2,
      },
      {
        name: "Mangia 57th - Midtown Italian Food & Corporate Catering NYC",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: 9,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.76409588367172,
    longitude: -73.98587887066748,
    rank: 20,
    places: [
      {
        name: "Via Toscana",
        rank: 1,
      },
      {
        name: "The Silver Stein",
        rank: 2,
      },
      {
        name: "VV Bar & Kitchen",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: 20,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.76409586998154,
    longitude: -73.9840978109555,
    rank: 8,
    places: [
      {
        name: "The Silver Stein",
        rank: 1,
      },
      {
        name: "Springfield Ale House",
        rank: 2,
      },
      {
        name: "The Cozy Corner Pub",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: 8,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.764095856291355,
    longitude: -73.98231675124389,
    rank: 5,
    places: [
      {
        name: "The Green Dragon",
        rank: 1,
      },
      {
        name: "Springfield Ale House",
        rank: 2,
      },
      {
        name: "The Drunken Clam",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: 5,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.764095842601165,
    longitude: -73.98053569153264,
    rank: 1,
    places: [
      {
        name: "Moe's tavern",
        rank: 1,
        owner: true,
      },
      {
        name: "The Green Dragon",
        rank: 2,
      },
      {
        name: "The Crafty Brewer",
        rank: 3,
      },
    ],
  },
  {
    latitude: 40.764095828910975,
    longitude: -73.97875463182176,
    rank: 2,
    places: [
      {
        name: "The Crafty Brewer",
        rank: 1,
      },
      {
        name: "Moe's tavern",
        rank: 2,
        owner: true,
      },
      {
        name: "The Barrel Room",
        rank: 3,
      },
    ],
  },
  {
    latitude: 40.764095815220784,
    longitude: -73.97697357211123,
    rank: 11,
    places: [
      {
        name: "The Barrel Room",
        rank: 1,
      },
      {
        name: "Mangia 57th - Midtown Italian Food & Corporate Catering NYC",
        rank: 2,
      },
      {
        name: "The Crafty Brewer",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: 11,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.7640958015306,
    longitude: -73.97519251240108,
    rank: 12,
    places: [
      {
        name: "Cucina 8 1/2",
        rank: 1,
      },
      {
        name: "The Barrel Room",
        rank: 2,
      },
      {
        name: "Felice 56",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: 12,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.76274632629837,
    longitude: -73.9858786537141,
    rank: null,
    places: [
      {
        name: "The Urban Bar",
        rank: 1,
      },
      {
        name: "The Happy Hour Haven",
        rank: 2,
      },
      {
        name: "The Local Pint",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: null,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.76274631260883,
    longitude: -73.98409763016657,
    rank: 12,
    places: [
      {
        name: "The Urban Bar",
        rank: 1,
      },
      {
        name: "Springfield Ale House",
        rank: 2,
      },
      {
        name: "The Silver Stein",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: 12,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.7627462989193,
    longitude: -73.98231660661942,
    rank: 6,
    places: [
      {
        name: "Springfield Ale House",
        rank: 1,
      },
      {
        name: "The Green Dragon",
        rank: 2,
      },
      {
        name: "The Barrel Room",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: 6,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.76274628522977,
    longitude: -73.98053558307264,
    rank: 5,
    places: [
      {
        name: "The Barrel Room",
        rank: 1,
      },
      {
        name: "The Crafty Brewer",
        rank: 2,
      },
      {
        name: "The Green Dragon",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: 5,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.76274627154024,
    longitude: -73.97875455952621,
    rank: 7,
    places: [
      {
        name: "The Crafty Brewer",
        rank: 1,
      },
      {
        name: "Il Corso",
        rank: 2,
      },
      {
        name: "Bice Cucina - Midtown",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: 7,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.76274625785071,
    longitude: -73.97697353598015,
    rank: 10,
    places: [
      {
        name: "Il Corso",
        rank: 1,
      },
      {
        name: "Bice Cucina - Midtown",
        rank: 2,
      },
      {
        name: "PizzArte",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: 10,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.76274624416117,
    longitude: -73.97519251243446,
    rank: 16,
    places: [
      {
        name: "Felice 56",
        rank: 1,
      },
      {
        name: "Il Tinello",
        rank: 2,
      },
      {
        name: "Il Gattopardo",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: 16,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.761396768952395,
    longitude: -73.98587843677547,
    rank: null,
    places: [
      {
        name: "Serafina Italian Restaurant Time Hotel",
        rank: 1,
      },
      {
        name: "La Masseria NY",
        rank: 2,
      },
      {
        name: "Da Marino NYC",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: null,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.76139675526351,
    longitude: -73.98409744938995,
    rank: null,
    places: [
      {
        name: "Da Marino NYC",
        rank: 1,
      },
      {
        name: "The Urban Bar",
        rank: 2,
      },
      {
        name: "Serafina Italian Restaurant Time Hotel",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: null,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.76139674157463,
    longitude: -73.9823164620048,
    rank: 7,
    places: [
      {
        name: "Carmine's",
        rank: 1,
      },
      {
        name: "Tony's Di Napoli",
        rank: 2,
      },
      {
        name: "The Drunken Clam",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: 7,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.761396727885746,
    longitude: -73.98053547462003,
    rank: 7,
    places: [
      {
        name: "The Barrel Room",
        rank: 1,
      },
      {
        name: "Pasta Lovers",
        rank: 2,
      },
      {
        name: "Il Corso",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: 7,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.76139671419686,
    longitude: -73.97875448723559,
    rank: 11,
    places: [
      {
        name: "Il Corso",
        rank: 1,
      },
      {
        name: "Bice Cucina - Midtown",
        rank: 2,
      },
      {
        name: "Il Gattopardo",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: 11,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.76139670050798,
    longitude: -73.97697349985154,
    rank: 13,
    places: [
      {
        name: "Il Gattopardo",
        rank: 1,
      },
      {
        name: "Il Corso",
        rank: 2,
      },
      {
        name: "Bice Cucina - Midtown",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: 13,
        owner: true,
      },
    ],
  },
  {
    latitude: 40.76139668681909,
    longitude: -73.97519251246786,
    rank: 18,
    places: [
      {
        name: "Il Gattopardo",
        rank: 1,
      },
      {
        name: "San Pietro",
        rank: 2,
      },
      {
        name: "Il Tinello",
        rank: 3,
      },
      {
        name: "Moe's tavern",
        rank: 18,
        owner: true,
      },
    ],
  },
];
const keywordDetails: Record<
  string,
  {
    info: KeywordInfo & Pick<Keyword, "label" | "active">;
    grid: GridPoint[];
    places: RankedPlace[];
    center: Coordinates;
  }
> = {
  "keyword-1": {
    info: {
      difficulty: 45,
      delta_rank: 1,
      average_rank: 7,
      volume: 6500,
      label: "Bars near me",
      active: true,
    },
    grid,
    center: place.location,
    places: [
      {
        id: "1",
        address: "",
        name: "Joe's Bar & Grill",
        rating: 4.2,
        rating_count: "150",
        average_rank: 8,
        categories: [],
        primary_category: "Best bar in Springfield",
      },
      {
        id: "2",
        address: "",
        name: "The Red Door Pub",
        rating: 4.1,
        rating_count: "180",
        average_rank: 11,
        categories: [],
        primary_category: "Local taverns near me",
      },
      {
        id: "3",
        address: "",
        name: "The Barrel Room",
        rating: 4.3,
        rating_count: "150",
        average_rank: 13,
        categories: [],
        primary_category: "Best bar in Springfield",
      },
      {
        id: "4",
        address: "",
        name: "The Drunken Clam",
        rating: 4.3,
        rating_count: "180",
        average_rank: 4,
        categories: [],
        primary_category: "Bars near me",
      },
      {
        id: "5",
        address: "",
        name: "The Lucky Leprechaun",
        rating: 4.3,
        rating_count: "200",
        average_rank: 5,
        categories: [],
        primary_category: "Springfield bar specials",
      },
    ],
  },
  "keyword-2": {
    info: {
      difficulty: 40,
      delta_rank: 2,
      average_rank: 8,
      volume: 4800,
      label: "Local taverns near me",
      active: true,
    },
    grid,
    center: place.location,
    places: [
      {
        id: "1",
        address: "",
        name: "The Drunken Clam",
        rating: 4,
        rating_count: "200",
        average_rank: 12,
        categories: [],
        primary_category: "Bars near me",
      },
      {
        id: "2",
        address: "",
        name: "The Lucky Leprechaun",
        rating: 4.3,
        rating_count: "200",
        average_rank: 5,
        categories: [],
        primary_category: "Best bar in Springfield",
      },
      {
        id: "3",
        address: "",
        name: "The Hoppy Place",
        rating: 4.1,
        rating_count: "190",
        average_rank: 11,
        categories: [],
        primary_category: "Bars near me",
      },
      {
        id: "4",
        address: "",
        name: "Barney's Pub",
        rating: 4.5,
        rating_count: "250",
        average_rank: 15,
        categories: [],
        primary_category: "Local taverns near me",
      },
      {
        id: "5",
        address: "",
        name: "The Golden Tap",
        rating: 3.7,
        rating_count: "160",
        average_rank: 13,
        categories: [],
        primary_category: "Bars near me",
      },
    ],
  },
  "keyword-3": {
    info: {
      difficulty: 50,
      delta_rank: 0,
      average_rank: 5,
      volume: 3200,
      label: "Best bar in Springfield",
      active: false,
    },
    grid,
    center: place.location,
    places: [
      {
        id: "1",
        address: "",
        name: "Barney's Pub",
        rating: 3.8,
        rating_count: "180",
        average_rank: 15,
        categories: [],
        primary_category: "Local taverns near me",
      },
      {
        id: "2",
        address: "",
        name: "The Golden Tap",
        rating: 4,
        rating_count: "220",
        average_rank: 13,
        categories: [],
        primary_category: "Bars near me",
      },
      {
        id: "3",
        address: "",
        name: "The Sunset Saloon",
        rating: 4,
        rating_count: "170",
        average_rank: 6,
        categories: [],
        primary_category: "Local taverns near me",
      },
      {
        id: "4",
        address: "",
        name: "Duff Brewery Taproom",
        rating: 4,
        rating_count: "220",
        average_rank: 4,
        categories: [],
        primary_category: "Best bar in Springfield",
      },
      {
        id: "5",
        address: "",
        name: "Brewer's Alley",
        rating: 4.1,
        rating_count: "190",
        average_rank: 7,
        categories: [],
        primary_category: "Local taverns near me",
      },
    ],
  },
  "keyword-4": {
    info: {
      difficulty: 35,
      delta_rank: -1,
      average_rank: 4,
      volume: 2900,
      label: "Happy hour Springfield",
      active: false,
    },
    grid,
    center: place.location,
    places: [
      {
        id: "1",
        address: "",
        name: "Duff Brewery Taproom",
        rating: 4.5,
        rating_count: "250",
        average_rank: 4,
        categories: [],
        primary_category: "Springfield bar specials",
      },
      {
        id: "2",
        address: "",
        name: "Brewer's Alley",
        rating: 4.4,
        rating_count: "150",
        average_rank: 7,
        categories: [],
        primary_category: "Happy hour Springfield",
      },
      {
        id: "3",
        address: "",
        name: "The Village Tavern",
        rating: 4.2,
        rating_count: "160",
        average_rank: 14,
        categories: [],
        primary_category: "Springfield bar specials",
      },
      {
        id: "4",
        address: "",
        name: "The Tipsy Moose",
        rating: 4.3,
        rating_count: "200",
        average_rank: 10,
        categories: [],
        primary_category: "Happy hour Springfield",
      },
      {
        id: "5",
        address: "",
        name: "The Rusty Anchor",
        rating: 4.2,
        rating_count: "170",
        average_rank: 17,
        categories: [],
        primary_category: "Best bar in Springfield",
      },
    ],
  },
  "keyword-5": {
    info: {
      difficulty: 30,
      delta_rank: 1,
      average_rank: 6,
      volume: 2500,
      label: "Springfield bar specials",
      active: false,
    },
    grid,
    center: place.location,
    places: [
      {
        id: "1",
        address: "",
        name: "The Tipsy Moose",
        rating: 4.1,
        rating_count: "160",
        average_rank: 10,
        categories: [],
        primary_category: "Happy hour Springfield",
      },
      {
        id: "2",
        address: "",
        name: "The Rusty Anchor",
        rating: 3.7,
        rating_count: "170",
        average_rank: 17,
        categories: [],
        primary_category: "Springfield bar specials",
      },
      {
        id: "3",
        address: "",
        name: "The Blue Moon Bar",
        rating: 4.3,
        rating_count: "210",
        average_rank: 8,
        categories: [],
        primary_category: "Happy hour Springfield",
      },
      {
        id: "4",
        address: "",
        name: "Springfield Brewhouse",
        rating: 4.1,
        rating_count: "160",
        average_rank: 6,
        categories: [],
        primary_category: "Springfield bar specials",
      },
      {
        id: "5",
        address: "",
        name: "Paddy's Pub",
        rating: 4.2,
        rating_count: "210",
        average_rank: 8,
        categories: [],
        primary_category: "Happy hour Springfield",
      },
    ],
  },
  "keyword-6": {
    info: {
      difficulty: 45,
      delta_rank: 0,
      average_rank: 5,
      volume: 1800,
      label: "Springfield bar and grill",
      active: false,
    },
    grid,
    center: place.location,
    places: [
      {
        id: "1",
        address: "",
        name: "Springfield Brewhouse",
        rating: 4.3,
        rating_count: "140",
        average_rank: 6,
        categories: [],
        primary_category: "Craft beer Springfield",
      },
      {
        id: "2",
        address: "",
        name: "Paddy's Pub",
        rating: 4.2,
        rating_count: "190",
        average_rank: 8,
        categories: [],
        primary_category: "Craft beer Springfield",
      },
      {
        id: "3",
        address: "",
        name: "The Heritage Pub",
        rating: 4.1,
        rating_count: "180",
        average_rank: 7,
        categories: [],
        primary_category: "Craft beer Springfield",
      },
      {
        id: "4",
        address: "",
        name: "The Copper Mug",
        rating: 4,
        rating_count: "220",
        average_rank: 14,
        categories: [],
        primary_category: "Springfield bar and grill",
      },
      {
        id: "5",
        address: "",
        name: "The Local Pint",
        rating: 4.3,
        rating_count: "190",
        average_rank: 10,
        categories: [],
        primary_category: "Springfield bar and grill",
      },
    ],
  },
  "keyword-7": {
    info: {
      difficulty: 40,
      delta_rank: 2,
      average_rank: 7,
      volume: 1600,
      label: "Craft beer Springfield",
      active: false,
    },
    grid,
    center: place.location,
    places: [
      {
        id: "1",
        address: "",
        name: "The Copper Mug",
        rating: 4,
        rating_count: "220",
        average_rank: 14,
        categories: [],
        primary_category: "Springfield tavern reviews",
      },
      {
        id: "2",
        address: "",
        name: "The Local Pint",
        rating: 4.1,
        rating_count: "160",
        average_rank: 10,
        categories: [],
        primary_category: "Best tavern Springfield",
      },
      {
        id: "3",
        address: "",
        name: "The Urban Bar",
        rating: 4,
        rating_count: "210",
        average_rank: 12,
        categories: [],
        primary_category: "Springfield tavern reviews",
      },
      {
        id: "4",
        address: "",
        name: "The Happy Hour Haven",
        rating: 4.3,
        rating_count: "140",
        average_rank: 7,
        categories: [],
        primary_category: "Craft beer Springfield",
      },
      {
        id: "5",
        address: "",
        name: "The Laughing Fox",
        rating: 4.1,
        rating_count: "190",
        average_rank: 12,
        categories: [],
        primary_category: "Best tavern Springfield",
      },
    ],
  },
  "keyword-8": {
    info: {
      difficulty: 35,
      delta_rank: 1,
      average_rank: 6,
      volume: 1200,
      label: "Springfield tavern reviews",
      active: false,
    },
    grid,
    center: place.location,
    places: [
      {
        id: "1",
        address: "",
        name: "The Happy Hour Haven",
        rating: 4.4,
        rating_count: "190",
        average_rank: 7,
        categories: [],
        primary_category: "Best tavern Springfield",
      },
      {
        id: "2",
        address: "",
        name: "The Laughing Fox",
        rating: 4,
        rating_count: "210",
        average_rank: 12,
        categories: [],
        primary_category: "Springfield bar and grill",
      },
      {
        id: "3",
        address: "",
        name: "The Cozy Corner Pub",
        rating: 5,
        rating_count: "35",
        average_rank: 2,
        categories: [],
        primary_category: "Springfield tavern reviews",
      },
      {
        id: "4",
        address: "",
        name: "Springfield Ale House",
        rating: 4.2,
        rating_count: "170",
        average_rank: 6,
        categories: [],
        primary_category: "Springfield tavern reviews",
      },
      {
        id: "5",
        address: "",
        name: "The Silver Stein",
        rating: 4,
        rating_count: "170",
        average_rank: 6,
        categories: [],
        primary_category: "Springfield tavern reviews",
      },
    ],
  },
  "keyword-9": {
    info: {
      difficulty: 38,
      delta_rank: 0,
      average_rank: 5,
      volume: 2100,
      label: "Best tavern Springfield",
      active: false,
    },
    grid,
    center: place.location,
    places: [
      {
        id: "1",
        address: "",
        name: "Springfield Ale House",
        rating: 4.2,
        rating_count: "170",
        average_rank: 9,
        categories: [],
        primary_category: "Springfield bar and grill",
      },
      {
        id: "2",
        address: "",
        name: "The Silver Stein",
        rating: 4.3,
        rating_count: "180",
        average_rank: 6,
        categories: [],
        primary_category: "Springfield bar specials",
      },
      {
        id: "3",
        address: "",
        name: "The Crafty Brewer",
        rating: 4.3,
        rating_count: "180",
        average_rank: 6,
        categories: [],
        primary_category: "Best tavern Springfield",
      },
      {
        id: "4",
        address: "",
        name: "O'Hara's Tavern",
        rating: 3.9,
        rating_count: "210",
        average_rank: 16,
        categories: [],
        primary_category: "Springfield tavern reviews",
      },
      {
        id: "5",
        address: "",
        name: "The Blue Moon Bar",
        rating: 4.3,
        rating_count: "200",
        average_rank: 5,
        categories: [],
        primary_category: "Springfield bar specials",
      },
    ],
  },
  "keyword-10": {
    info: {
      difficulty: 42,
      delta_rank: 1,
      average_rank: 6,
      volume: 3000,
      label: "Nightlife in Springfield",
      active: false,
    },
    grid,
    center: place.location,
    places: [
      {
        id: "1",
        address: "",
        name: "O'Hara's Tavern",
        rating: 3.9,
        rating_count: "210",
        average_rank: 16,
        categories: [],
        primary_category: "Springfield bar specials",
      },
      {
        id: "2",
        address: "",
        name: "The Crafty Brewer",
        rating: 4.4,
        rating_count: "200",
        average_rank: 5,
        categories: [],
        primary_category: "Nightlife in Springfield",
      },
      {
        id: "3",
        address: "",
        name: "The Green Dragon",
        rating: 4,
        rating_count: "160",
        average_rank: 13,
        categories: [],
        primary_category: "Springfield bar and grill",
      },
      {
        id: "4",
        address: "",
        name: "The Red Door Pub",
        rating: 4,
        rating_count: "190",
        average_rank: 11,
        categories: [],
        primary_category: "Nightlife in Springfield",
      },
      {
        id: "5",
        address: "",
        name: "The Barrel Room",
        rating: 4,
        rating_count: "160",
        average_rank: 13,
        categories: [],
        primary_category: "Springfield bar specials",
      },
    ],
  },
};

const detailedPlace: DetailedPlace = {
  ...place,
  city: "Springfield",
  country: "USA",
  keywords: Object.entries(keywordDetails).map(
    ([
      key,
      {
        info: { volume, ...rest },
      },
    ]) => ({
      id: key,
      search_volume: volume,
      state: "ready",
      grid_state: "ready",
      snapshot_dates: [new Date().toISOString().split("T")[0]],
      ...rest,
    }),
  ),
  drives: 20,
  drives_remaining: 7,
  subscription: {
    ...place.subscription,
    item: { external_name: "Demo", max_keywords: 10 },
  },
};
const reviews: Reviews = {
  current: [
    {
      id: "1",
      rating: 5,
      text: "Moe's is my second home! The Duff Beer is always cold, and Moe keeps the bar just how I like it – full of laughs and good times. You can't beat this place!",
      internal_review: true,
      author: { name: "Homer Simpson", profile_picture: "/avatars/homer.png" },
      created_at: new Date(2024, 5, 21).toISOString(),
    },
    {
      id: "2",
      rating: 4,
      text: "I don't usually go to bars, but Moe's has a surprisingly cozy atmosphere. The service is friendly, and I enjoyed the mocktails they offer. A nice spot for a quick break.",
      internal_review: false,
      author: { name: "Marge Simpson", profile_picture: "/avatars/marge.png" },
      created_at: new Date(2024, 5, 19).toISOString(),
    },
    {
      id: "3",
      rating: 5,
      text: "Ay caramba! Moe's is awesome! Great place to chill with friends, and they even have Squishees! Moe is the best barkeep in Springfield. Totally recommend it!",
      internal_review: true,
      author: { name: "Bart Simpson", profile_picture: "/avatars/bart.png" },
      created_at: new Date(2024, 5, 17).toISOString(),
    },
    {
      id: "4",
      rating: 3,
      text: "Moe's Tavern is alright, but it could use a bit of an upgrade. The ambience is... rustic. It's a good spot if you’re into that sort of thing. The live jazz nights are a nice touch though.",
      internal_review: false,
      author: { name: "Lisa Simpson", profile_picture: "/avatars/lisa.png" },
      created_at: new Date(2024, 5, 15).toISOString(),
    },
    {
      id: "5",
      rating: 4,
      text: "Excellent! Moe's Tavern is a fine establishment. The service is impeccable, and the atmosphere is delightfully old-fashioned. I shall return.",
      internal_review: false,
      author: { name: "Mr. Burns", profile_picture: "/avatars/burns.png" },
      created_at: new Date(2024, 5, 13).toISOString(),
    },
    {
      id: "6",
      rating: 5,
      text: "Well, howdy-doodly! Moe's is a fantastic place for a root beer float and some neighborly conversation. Moe is always friendly and welcoming. Highly recommend for a wholesome time!",
      internal_review: false,
      author: { name: "Ned Flanders", profile_picture: "/avatars/ned.png" },
      created_at: new Date(2024, 5, 11).toISOString(),
    },
    {
      id: "7",
      rating: 4,
      text: "Hey hey! Moe's is a laugh riot. Great drinks, and Moe’s got jokes as good as mine. Perfect place to unwind after a long day of clowning around. Cheers!",
      internal_review: false,
      author: {
        name: "Krusty the Clown",
        profile_picture: "/avatars/krusty.png",
      },
      created_at: new Date(2024, 5, 9).toISOString(),
    },
    {
      id: "8",
      rating: 5,
      text: "Thank you, come again! Moe’s Tavern is a great place to relax and enjoy a drink after a long shift at the Kwik-E-Mart. Moe is a wonderful host. Highly recommended!",
      internal_review: false,
      author: {
        name: "Apu Nahasapeemapetilon",
        profile_picture: "/avatars/apu.png",
      },
      created_at: new Date(2024, 5, 7).toISOString(),
    },
    {
      id: "9",
      rating: 4,
      text: "Moe's is the spot for some off-duty relaxation. The beer is always cold, and Moe keeps things lively. Great place for Springfield’s finest (and everyone else) to hang out.",
      internal_review: false,
      author: { name: "Chief Wiggum", profile_picture: "/avatars/wiggum.png" },
      created_at: new Date(2024, 5, 5).toISOString(),
    },
    {
      id: "10",
      rating: 3,
      text: "Moe’s Tavern is decent enough. The drinks are strong, and the crowd is colorful. It's a good place to wind down after a day of dealing with Bart Simpson.",
      internal_review: false,
      author: { name: "Edna Krabappel", profile_picture: "/avatars/edna.png" },
      created_at: new Date(2024, 5, 3).toISOString(),
    },
    {
      id: "11",
      rating: 5,
      text: "Best. Bar. Ever. Moe’s Tavern is a haven for aficionados of fine beer. Moe’s encyclopedic knowledge of ales and lagers is unparalleled. Five stars.",
      internal_review: true,
      author: { name: "Comic Book Guy", profile_picture: "/avatars/comic.png" },
      created_at: new Date(2024, 5, 1).toISOString(),
    },
  ],
  pending: [],
  reviews_remaining: 2,
  auto_accept: false,
  next_review_at: Date.now() + 1000 * 60 * 60 * 24 * 3,
};

const dummyData = { place, detailedPlace, keywordDetails, reviews };
export default dummyData;
