import React, { useState } from "react";
import PlaceSelection from "./PlaceSelection.tsx";
import NavLinks from "@/src/components/layout/NavLinks.tsx";
import { Dialog } from "react-aria-components";
import ChangePasswordModal from "@/src/components/layout/ChangePasswordModal.tsx";
import ManageSubscriptions from "@/src/components/layout/ManageSubscriptions.tsx";
import useCustomer from "@/src/datahooks/useCustomer.ts";
import Popover from "@/src/components/base/Popover.tsx";
import Button from "@/src/components/base/Button.tsx";
import { logout } from "@/src/helpers/auth.ts";

import UserIcon from "fontawesome/solid/user.svg?react";
import Logo from "@/src/assets/logo.svg?react";

function MenuItem({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick: () => void;
}) {
  return (
    <button
      className="flex items-center gap-x-2.5 px-4 py-2.5 text-sm text-gray-900 hover:bg-gray-100"
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export default function NavBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { customer } = useCustomer();
  const triggerRef = React.useRef(null);

  return (
    <nav className="fixed inset-y-4 left-4 z-10 mr-4 hidden w-[76px] flex-col items-center rounded-lg bg-white py-4 shadow lg:flex">
      <Logo className="mb-6 w-[44px]" />
      <PlaceSelection />
      <div className="flex flex-col space-y-6 border-t border-t-gray-300 pt-6">
        <NavLinks />
      </div>
      <Button
        ref={triggerRef}
        className={`group mt-auto flex size-10 items-center justify-center rounded-full border ${isMenuOpen ? "border-blue-600 bg-blue-600" : "border-gray-300 data-[hovered]:border-blue-600 data-[hovered]:bg-blue-600"}`}
        onPress={() => setIsMenuOpen(true)}
      >
        <UserIcon
          className={`h-4 fill-gray-300 ${isMenuOpen ? "fill-white" : "group-data-[hovered]:fill-white"}`}
        />
      </Button>
      <Popover
        triggerRef={triggerRef}
        isOpen={isMenuOpen}
        placement="right bottom"
        offset={24}
        onOpenChange={setIsMenuOpen}
      >
        <Dialog className="flex flex-col">
          <div className="bg-gray-100 px-4 py-2.5 text-sm text-gray-900">
            {customer && customer.email}
          </div>
          <ChangePasswordModal />
          <ManageSubscriptions onClose={() => setIsMenuOpen(false)} />
          <MenuItem onClick={logout}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
            >
              <path
                d="M11.4871 8.85C11.577 8.62576 11.577 8.37391 11.4871 8.14967C11.4428 8.03781 11.3772 7.93621 11.2942 7.85084L7.73867 4.18417C7.65667 4.09662 7.55859 4.02679 7.45014 3.97874C7.34169 3.9307 7.22505 3.90542 7.10702 3.90436C6.989 3.9033 6.87195 3.92649 6.76271 3.97258C6.65347 4.01867 6.55422 4.08674 6.47076 4.17281C6.3873 4.25888 6.3213 4.36122 6.2766 4.47388C6.23191 4.58653 6.20942 4.70724 6.21044 4.82896C6.21147 4.95067 6.23599 5.07095 6.28258 5.18279C6.32916 5.29463 6.39688 5.39578 6.48178 5.48034L8.52089 7.58317H0.888889C0.653141 7.58317 0.427048 7.67975 0.260349 7.85166C0.0936505 8.02356 0 8.25672 0 8.49984C0 8.74295 0.0936505 8.97611 0.260349 9.14802C0.427048 9.31993 0.653141 9.4165 0.888889 9.4165H8.52089L6.48267 11.5184C6.39777 11.603 6.33005 11.7041 6.28346 11.816C6.23688 11.9278 6.21236 12.0481 6.21133 12.1698C6.21031 12.2915 6.2328 12.4122 6.27749 12.5249C6.32219 12.6375 6.38819 12.7399 6.47165 12.8259C6.55511 12.912 6.65436 12.9801 6.7636 13.0262C6.87284 13.0723 6.98989 13.0955 7.10791 13.0944C7.22594 13.0933 7.34258 13.0681 7.45103 13.02C7.55947 12.972 7.65756 12.9021 7.73956 12.8146L11.2951 9.14792C11.3776 9.06274 11.4429 8.96146 11.4871 8.85Z"
                fill="#111928"
              />
              <path
                d="M13.3333 15.8332H10.6667C10.4309 15.8332 10.2048 15.7366 10.0381 15.5647C9.87143 15.3928 9.77778 15.1596 9.77778 14.9165C9.77778 14.6734 9.87143 14.4402 10.0381 14.2683C10.2048 14.0964 10.4309 13.9998 10.6667 13.9998H13.3333C13.5691 13.9998 13.7952 13.9033 13.9619 13.7314C14.1286 13.5594 14.2222 13.3263 14.2222 13.0832V3.9165C14.2222 3.67339 14.1286 3.44023 13.9619 3.26832C13.7952 3.09641 13.5691 2.99984 13.3333 2.99984H10.6667C10.4309 2.99984 10.2048 2.90326 10.0381 2.73135C9.87143 2.55944 9.77778 2.32629 9.77778 2.08317C9.77778 1.84006 9.87143 1.6069 10.0381 1.43499C10.2048 1.26308 10.4309 1.1665 10.6667 1.1665H13.3333C14.0406 1.1665 14.7189 1.45624 15.219 1.97196C15.719 2.48769 16 3.18716 16 3.9165V13.0832C16 13.8125 15.719 14.512 15.219 15.0277C14.7189 15.5434 14.0406 15.8332 13.3333 15.8332Z"
                fill="#111928"
              />
            </svg>
            Log out
          </MenuItem>
        </Dialog>
      </Popover>
    </nav>
  );
}
