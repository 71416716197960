import { get, patch } from "@/src/helpers/HTTP.ts";
import { useMutation } from "@tanstack/react-query";

async function createSettingsUrlRequest() {
  const { portal_session } = await get(`v1/customers/settings`);
  return portal_session;
}
async function updatePasswordRequest(password: string) {
  await patch(`v1/auth/password`, { password });
}
export default function useCustomerMutations() {
  const { mutateAsync: updatePassword, isPending: isUpdatingPassword } =
    useMutation({
      mutationFn: ({ password }: { password: string }) =>
        updatePasswordRequest(password),
    });
  const { mutateAsync: createSettingsUrl, isPending: isCreatingSettingsUrl } =
    useMutation({
      mutationFn: createSettingsUrlRequest,
    });
  return {
    updatePassword,
    isUpdatingPassword,
    createSettingsUrl,
    isCreatingSettingsUrl,
  };
}
