export function getQueryKeys() {
  const queryKeys = {
    places: {
      base: ["places"],
      list: () => [...queryKeys.places.base, "list"],
      details: (placeId: string) => [
        ...queryKeys.places.base,
        placeId,
        "details",
      ],
    },
    reviews: {
      base: ["reviews"],
      list: (placeId: string) => [...queryKeys.places.base, placeId],
    },
  };
  return queryKeys;
}
