import { create } from "zustand";
import { devtools } from "zustand/middleware";

export type AppStore = {
  isAddModalOpen: boolean;
  setIsAddModalOpen: (isAddModalOpen: boolean) => void;

  isUpsellModalOpen: boolean;
  setIsUpsellModalOpen: (isUpsellModalOpen: boolean) => void;

  isReactivateModalOpen: boolean;
  setIsReactivateModalOpen: (isReactivateModalOpen: boolean) => void;

  geoState: PermissionState;
  setGeoState: (geoState: PermissionState) => void;
};

export const useAppStore = create(
  devtools<AppStore>(
    (set) => ({
      isAddModalOpen: false,
      setIsAddModalOpen: (isAddModalOpen) => set({ isAddModalOpen }),

      isUpsellModalOpen: false,
      setIsUpsellModalOpen: (isUpsellModalOpen) => set({ isUpsellModalOpen }),

      isReactivateModalOpen: false,
      setIsReactivateModalOpen: (isReactivateModalOpen) =>
        set({ isReactivateModalOpen }),

      geoState: "prompt",
      setGeoState: (geoState) => set({ geoState }),
    }),
    { name: "App Store" },
  ),
);
