const maxItems = 2;
export default function Pagination({
  pagesCount,
  pageIndex,
  setPageIndex,
}: {
  pagesCount: number;
  pageIndex: number;
  setPageIndex: (pageIndex: number) => void;
}) {
  const pageComponents = [];
  for (let i = 0; i < pagesCount; i += 1) {
    const isSelected = pageIndex === i;
    if (
      pageIndex === i ||
      (pageIndex <= i + maxItems && pageIndex >= i - maxItems)
    ) {
      pageComponents.push(
        <button
          key={i}
          className={`${isSelected ? "bg-gray-100 text-gray-900" : "text-gray-400"} border-r border-r-gray-400 px-3 py-1.5 text-sm text-gray-400 hover:bg-gray-100`}
          onClick={() => setPageIndex(i)}
        >
          {i + 1}
        </button>,
      );
    }
  }

  return (
    <div className="mx-auto my-4 flex rounded border border-gray-400">
      <button
        disabled={pageIndex <= 0}
        className="rounded-l-[inherit] border-r border-r-gray-400 px-3 py-1.5 text-gray-400  hover:bg-gray-100"
        onClick={() => setPageIndex(pageIndex - 1)}
      >{`<`}</button>
      {pageComponents}
      <button
        disabled={pageIndex >= pagesCount - 1}
        className="rounded-r-[inherit] px-3 py-1.5 text-gray-400 hover:bg-gray-100"
        onClick={() => {
          setPageIndex(pageIndex + 1);
        }}
      >{`>`}</button>
    </div>
  );
}
