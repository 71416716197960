import useKeywordsMutations from "@/src/datahooks/useKeywordsMutations.tsx";
import { useAppStore } from "@/src/helpers/useAppStore.ts";

export default function ToggleKeyword({
  keywordId,
  isActive,
  canRemoveKeywords,
  canAddKeywords,
}: {
  keywordId: string;
  isActive: boolean;
  canAddKeywords: boolean;
  canRemoveKeywords: boolean;
}) {
  const { toggleKeyword, isTogglingKeyword } = useKeywordsMutations();

  function onClick() {
    if (!isActive && !canAddKeywords) {
      useAppStore.getState().setIsUpsellModalOpen(true);
    } else {
      toggleKeyword({ keywordId, isActive: !isActive });
    }
  }
  const isDisabled = isTogglingKeyword || (isActive && !canRemoveKeywords);

  let buttonLabel, colorClassName;

  if (isActive) {
    buttonLabel = "Disable";
    colorClassName = "bg-red-600";
  } else {
    if (canAddKeywords) {
      buttonLabel = "Enable";
      colorClassName = "bg-blue-600";
    } else {
      buttonLabel = "Upgrade";
      colorClassName = "bg-purple-500";
    }
  }

  return (
    <button
      disabled={isTogglingKeyword || isDisabled}
      className={`w-20 rounded-lg py-1.5 text-sm text-white lg:w-24 lg:py-2 ${colorClassName} ${(isDisabled || isTogglingKeyword) && "opacity-50"}`}
      onClick={onClick}
    >
      {buttonLabel}
    </button>
  );
}
