export default function Welcome({ onNextStep }: { onNextStep: () => void }) {
  return (
    <>
      <span className="mb-2 text-center text-xl font-medium lg:text-2xl">
        Welcome to HeyRank!
      </span>
      <span className="mb-6 text-center">
        You&apos;re almost there! Before starting the 7-day trial, we need to
        find your Google Business Profile to connect it with our growth system
      </span>
      <button
        className="w-3/4 flex-1 rounded-lg bg-blue-600 py-4 font-medium text-white"
        onClick={onNextStep}
      >
        Let&apos;s get started
      </button>
    </>
  );
}
