import { Place } from "@/src/types.ts";
import ReviewStars from "@/src/components/ReviewStars.tsx";

export default function PlaceRating({
  rating,
  ratingCount,
  variant,
}: { ratingCount: string; variant: "large" | "small" } & Pick<
  Place,
  "rating"
>) {
  return (
    <div className="flex gap-x-2">
      <span
        className={
          variant === "large"
            ? "text-sm font-medium lg:text-base"
            : "text-xs lg:text-sm"
        }
      >
        {ratingCount === "0" ? "N/A" : rating}
      </span>
      <ReviewStars rating={rating} />
      <span
        className={
          variant === "large"
            ? "text-sm font-medium lg:text-base"
            : "text-xs text-gray-400 lg:text-sm"
        }
      >
        ({ratingCount})
      </span>
    </div>
  );
}
