import { createRootRoute, Outlet, redirect } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { jwtDecode } from "jwt-decode";
import { getAuthUrl } from "@/src/helpers/auth.ts";
import { placesQueryOptions } from "@/src/datahooks/usePlaces.ts";
import dummyData from "@/src/dummyData.ts";
import { useEffect } from "react";
import { useAppStore } from "@/src/helpers/useAppStore.ts";
import { IntercomProvider } from "react-use-intercom";
import { init } from "@sentry/browser";

init({
  dsn: "https://78f73c88d19cab39303d9d774bb8abee@o4507312289284096.ingest.us.sentry.io/4507368857403392",
  integrations: [],
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
});

const { hash } = window.location;
if (hash) {
  const token = hash.substring(1);

  if (token !== "") {
    localStorage.setItem("auth", token);
  }
}
export const Route = createRootRoute({
  beforeLoad: async () => {
    const token = localStorage.getItem("auth");
    let isAuthenticated = false;

    if (token) {
      const { exp } = jwtDecode(token);
      const expirationTime = (exp as number) * 1000 - 60000;

      if (Date.now() < expirationTime) {
        isAuthenticated = true;
      }
    }
    if (!isAuthenticated) {
      window.location.href = await getAuthUrl();
    }
  },
  loader: async ({ params }) => {
    const places = await queryClient.ensureQueryData(placesQueryOptions);
    let redirectToPlaceId;

    if ("placeId" in params) {
      /* empty */
    } else {
      if (places.length) {
        redirectToPlaceId = places[0].id;
      } else {
        redirectToPlaceId = dummyData.place.id;
      }
    }

    document.getElementById("loader-container")?.remove();
    if (redirectToPlaceId) {
      throw redirect({
        to: "/$placeId/home",
        replace: true,
        params: { placeId: redirectToPlaceId },
        search: (search) => search,
      });
    }
  },
  component: Root,
});
function Root() {
  useEffect(() => {
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      useAppStore.getState().setGeoState(result.state);
    });
  }, []);
  return (
    <>
      <IntercomProvider appId={import.meta.env.VITE_INTERCOM_APP_ID} autoBoot>
        <QueryClientProvider client={queryClient}>
          <Outlet />
          <ReactQueryDevtools />
        </QueryClientProvider>
      </IntercomProvider>
      {process.env.NODE_ENV !== "production" && <TanStackRouterDevtools />}
    </>
  );
}
