import { JSX } from "react";
import Card from "@/src/components/Card.tsx";
import { useSelectedPlace } from "@/src/contexts.ts";
import PlaceRating from "@/src/components/PlaceRating.tsx";

export default function BannerCard({ children }: { children: JSX.Element }) {
  const { selectedPlace } = useSelectedPlace();
  const { rating, rating_count, address, name } = selectedPlace;

  return (
    <Card className="flex items-center justify-between gap-x-4 p-4 lg:p-6">
      <div className="flex items-center overflow-x-hidden">
        <div className="mr-4 flex size-[50px] shrink-0 items-center justify-center rounded-full bg-orange-400 text-2xl text-white lg:mr-6 lg:size-[84px] lg:text-5xl">
          {name[0]}
        </div>
        <div className="flex flex-col gap-y-0 overflow-x-hidden">
          <PlaceRating
            rating={rating}
            ratingCount={rating_count}
            variant="large"
          />
          <span className="truncate text-xl font-bold lg:text-3xl">{name}</span>
          <span className="block truncate text-sm lg:text-base">{address}</span>
        </div>
      </div>
      {children}
    </Card>
  );
}
