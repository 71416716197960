import usePlaces from "@/src/datahooks/usePlaces";
import { SelectedPlaceProvider } from "@/src/contexts";
import dummyData from "@/src/dummyData";
import MobileHeader from "@/src/components/layout/MobileHeader";
import NavBar from "@/src/components/layout/NavBar";
import AddPlaceModal from "@/src/components/layout/AddPlaceModal/AddPlaceModal.tsx";
import UpsellModal from "@/src/components/layout/UpsellModal.tsx";
import ReactivateSubscriptionModal from "@/src/components/layout/ReactivateSubscriptionModal.tsx";
import { createFileRoute, Outlet } from "@tanstack/react-router";

export const Route = createFileRoute("/$placeId/_layout")({
  component: Layout,
});
function Layout() {
  const { placeId } = Route.useParams();
  const { places } = usePlaces();

  let selectedPlace;
  if (placeId === dummyData.place.id) {
    selectedPlace = dummyData.place;
  } else if (places) {
    const foundPlace = places.find(({ id }) => id === placeId);
    if (foundPlace) {
      selectedPlace = foundPlace;
    }
  }
  return (
    <SelectedPlaceProvider value={selectedPlace}>
      <MobileHeader />
      <NavBar />
      <main className="mt-16 flex min-h-screen w-full flex-1 flex-col gap-y-4 overflow-y-hidden p-4 lg:ml-[92px] lg:mt-0">
        <Outlet />
      </main>
      <AddPlaceModal />
      <UpsellModal />
      <ReactivateSubscriptionModal />
    </SelectedPlaceProvider>
  );
}
