import { Dialog } from "react-aria-components";

import TriangleExclamationIcon from "fontawesome/solid/triangle-exclamation.svg?react";

export default function ErrorDialog({
  isOpen,
  onRetry,
}: {
  isOpen: boolean;
  onRetry: () => void;
}) {
  return (
    <div
      className={`absolute inset-0 z-20 flex bg-gray-900/20 transition-opacity duration-300 ${isOpen ? "opacity-100" : "pointer-events-none opacity-0"}`}
    >
      <Dialog
        className={`m-auto flex w-[90%] flex-col items-center rounded-lg bg-white p-6 pb-8 transition-transform duration-300 lg:w-[450px] ${isOpen ? "scale-100" : "scale-90"}`}
      >
        <TriangleExclamationIcon className="mb-3 mt-6 w-6 fill-blue-600" />
        <span className="mb-2 text-2xl font-medium">Something went wrong</span>
        <span className="mb-6 text-center">
          Don&apos;t worry, it&apos;s not you - we failed to fetch the freshest
          data from Google. Wait a couple of seconds and try again, but if the
          issue continues to occur, contact our support team.
        </span>
        <button
          onClick={onRetry}
          className="w-full rounded-lg bg-blue-600 py-4 font-medium text-white"
        >
          Try again
        </button>
      </Dialog>
    </div>
  );
}
