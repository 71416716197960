import { JSX } from "react";
import { useSelectedPlace } from "@/src/contexts.ts";
import Banners from "@/src/components/Banners.tsx";
import usePlace from "@/src/datahooks/usePlace.tsx";

export default function Header({
  children,
  label,
}: {
  children?: JSX.Element;
  label: string;
}) {
  const { selectedPlace } = useSelectedPlace();
  const { place } = usePlace(selectedPlace.id);

  return (
    <div className="flex flex-col-reverse justify-between gap-4 lg:mt-2 lg:h-[78px] lg:flex-row lg:border-b lg:border-b-gray-200 lg:pb-4">
      <div className="flex flex-col items-start overflow-x-hidden">
        <div className="mb-1 w-full overflow-x-hidden lg:truncate">
          <span className="font-medium lg:mb-2 lg:text-2xl">
            {selectedPlace && selectedPlace.name}&apos;s {label}
          </span>
        </div>
        {place && (
          <div className="rounded-md bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-500 lg:py-0 lg:text-base">
            {place.subscription.item.external_name}
          </div>
        )}
      </div>
      <Banners />
      <div className="hidden justify-end lg:flex">{children}</div>
    </div>
  );
}
