import { Layout } from "@chargebee/chargebee-js-types/enums";
import { useEffect, useRef, useState } from "react";

export function getRankName(rank: number) {
  if (rank < 4) {
    return "high";
  } else if (rank < 11) {
    return "med";
  } else {
    return "low";
  }
}
export function useDebounce(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState("");
  const timerRef = useRef<number>();

  useEffect(() => {
    timerRef.current = window.setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function openChargebeeHostedPage(
  hostedPage: unknown,
  onSuccess: () => void,
  onClose?: () => void,
) {
  const chargbeeInstance = window.Chargebee.getInstance();
  chargbeeInstance.openCheckout({
    hostedPage: async () => hostedPage,
    layout: Layout.IN_APP,
    close() {
      onClose && onClose();
    },
    error() {},
    loaded() {},
    step() {},
    success: async () => {
      chargbeeInstance.closeAll();
      onSuccess();
    },
  });
}
