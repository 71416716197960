import { ReactNode } from "react";
import ToggleKeyword from "@/src/components/home/KeywordsModal/ToggleKeyword.tsx";
import { RANKS } from "@/src/constants.ts";
import { getRankName } from "@/src/helpers/utils.ts";
import AddKeyword from "@/src/components/home/KeywordsModal/AddKeyword.tsx";
import { Keyword } from "@/src/types.ts";
import Button from "../../base/Button";

export default function KeywordsList({
  keywords,
  searchText,
  canAddKeywords,
  canRemoveKeywords,
}: {
  keywords: Keyword[];
  searchText: string;
  canAddKeywords: boolean;
  canRemoveKeywords: boolean;
}) {
  let listItems: ReactNode = (
    <div className="flex items-center justify-between p-4">
      <div className="flex flex-col gap-1">
        <div className="flex">
          <div
            className={`rounded-md px-2.5 py-0.5 text-xs ${RANKS.high.colorClassNames.primary.text} ${RANKS.high.colorClassNames.secondary.bg}`}
          >
            N/A
          </div>
        </div>
        <span className="text-sm font-medium">{searchText}</span>
        <span className="text-xs text-gray-500">Search volume: N/A</span>
      </div>
      <AddKeyword
        keywordLabel={searchText}
        renderTrigger={(addKeyword, isLoading) => (
          <Button
            className={`w-20 rounded-lg bg-blue-600 py-1.5 text-sm text-white ${isLoading && "opacity-50"}`}
            onPress={addKeyword}
            isDisabled={isLoading}
          >
            Select
          </Button>
        )}
      />
    </div>
  );

  if (keywords.length) {
    listItems = [...keywords]
      .sort()
      .map(({ id, active, difficulty, label, search_volume, state }) => {
        let difficultyContent: ReactNode = "N/A";
        let searchVolumeContent = "N/A";

        if (state === "ready") {
          const { colorClassNames } = RANKS[getRankName(difficulty)];
          difficultyContent = (
            <div className="flex">
              <div
                className={`rounded-md px-2.5 py-0.5 text-xs ${colorClassNames.primary.text} ${colorClassNames.secondary.bg}`}
              >
                {difficulty}
              </div>
            </div>
          );
          searchVolumeContent = search_volume.toString();
        }

        return (
          <div key={id} className="flex items-center justify-between p-4">
            <div className="flex flex-col gap-1">
              {difficultyContent}
              <span className="text-sm font-medium">{label}</span>
              <span className="text-xs text-gray-500">
                Search volume: {searchVolumeContent}
              </span>
            </div>
            <ToggleKeyword
              isActive={active}
              keywordId={id}
              canAddKeywords={canAddKeywords}
              canRemoveKeywords={canRemoveKeywords}
            />
          </div>
        );
      });
  }
  return (
    <div className="flex flex-col divide-y divide-gray-200 overflow-y-auto border-t border-t-gray-200 lg:hidden">
      {listItems}
    </div>
  );
}
