import { queryOptions, useQuery } from "@tanstack/react-query";
import { Place } from "@/src/types";
import { get } from "@/src/helpers/HTTP.ts";
import { getQueryKeys } from "@/src/helpers/getQueryKeys.ts";

async function getPlacesRequest(): Promise<Place[]> {
  const { places } = await get(`v1/places`);
  return places;
}
export const placesQueryOptions = queryOptions({
  queryKey: getQueryKeys().places.list(),
  queryFn: () => getPlacesRequest(),
  refetchOnMount: false,
});
export default function usePlaces() {
  const {
    data: places,
    isLoading: isLoadingPlaces,
    refetch: refetchPlaces,
  } = useQuery(placesQueryOptions);
  return { places, isLoadingPlaces, refetchPlaces };
}
