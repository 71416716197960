import { useMutation } from "@tanstack/react-query";
import { patch, post } from "@/src/helpers/HTTP.ts";

async function addPlaceRequest(placeId: string, placeName: string) {
  const { hosted_page } = await post(`v1/places`, {
    place_id: placeId,
    place_name: placeName,
  });
  return hosted_page;
}
async function reactivatePlaceRequest(placeId: string) {
  const { hosted_page } = await patch(`v1/places/${placeId}/reactivate`);
  return hosted_page;
}
async function upsellPlaceRequest(placeId: string) {
  const { hosted_page } = await patch(`v1/places/${placeId}`);
  return hosted_page;
}
export default function usePlaceMutations() {
  const { mutateAsync: addPlace, isPending: isAddingPlace } = useMutation({
    mutationFn: ({
      placeId,
      placeName,
    }: {
      placeId: string;
      placeName: string;
    }) => addPlaceRequest(placeId, placeName),
  });
  const { mutateAsync: reactivatePlace, isPending: isReactivatingPlace } =
    useMutation({
      mutationFn: ({ placeId }: { placeId: string }) =>
        reactivatePlaceRequest(placeId),
    });
  const { mutateAsync: upsellPlace, isPending: isUpsellingPlace } = useMutation(
    {
      mutationFn: ({ placeId }: { placeId: string }) =>
        upsellPlaceRequest(placeId),
    },
  );
  return {
    addPlace,
    isAddingPlace,
    reactivatePlace,
    isReactivatingPlace,
    upsellPlace,
    isUpsellingPlace,
  };
}
