import { useRef, useState } from "react";
import { BasePlace } from "@/src/types.ts";
import Modal from "@/src/components/base/Modal.tsx";
import { useAppStore } from "@/src/helpers/useAppStore.ts";
import PlaceSelection from "@/src/components/layout/AddPlaceModal/PlaceSelection.tsx";
import Welcome from "@/src/components/layout/AddPlaceModal/Welcome.tsx";
import usePlaces from "@/src/datahooks/usePlaces.ts";
import { useNavigate } from "@tanstack/react-router";
import useChargebeeUpdates from "@/src/datahooks/useChargebeeUpdates.tsx";
import ModalLoading from "@/src/components/ModalLoading.tsx";

/*
0 - welcome screen
1 - place selection
*/
export default function AddPlaceModal() {
  const { places } = usePlaces();
  const resetStateRef = useRef(false);
  const navigate = useNavigate();
  const [selectedPlace, setSelectedPlace] = useState<BasePlace | null>(null);
  const [currentStep, setCurrentStep] = useState(1);
  const isAddModalOpen = useAppStore((store) => store.isAddModalOpen);
  const { onAddPlace, isLoading } = useChargebeeUpdates();

  function resetState() {
    if (resetStateRef.current) {
      setSelectedPlace(null);
      setCurrentStep(places && places.length ? 1 : 0);
      resetStateRef.current = false;
    }
  }
  let content;
  if (isLoading) {
    content = <ModalLoading />;
  } else if (currentStep === 0) {
    content = <Welcome onNextStep={() => setCurrentStep(1)} />;
  } else if (currentStep === 1) {
    content = (
      <PlaceSelection
        selectedPlace={selectedPlace}
        setSelectedPlace={setSelectedPlace}
        onChargebeeSuccess={async () => {
          if (selectedPlace) {
            useAppStore.getState().setIsAddModalOpen(true);
            await onAddPlace(selectedPlace.id);
            resetStateRef.current = true;
            useAppStore.getState().setIsAddModalOpen(false);
            navigate({ params: { placeId: selectedPlace.id } });
          }
        }}
        onChargebeeOpen={() => {
          useAppStore.getState().setIsAddModalOpen(false);
        }}
        onChargebeeClose={() => {
          useAppStore.getState().setIsAddModalOpen(true);
        }}
      />
    );
  }
  return (
    <Modal
      isOpen={isAddModalOpen}
      onClose={
        isLoading
          ? undefined
          : () => {
              useAppStore.getState().setIsAddModalOpen(false);
            }
      }
      onAfterClose={resetState}
      className="items-center p-4 py-12 lg:w-[680px] lg:px-6"
    >
      {(currentStep === 0 || (currentStep === 1 && !selectedPlace)) && (
        <svg
          width="101"
          height="100"
          viewBox="0 0 101 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mb-4"
        >
          <rect x="0.5" width="100" height="100" rx="16" fill="#0044FF" />
          <path
            d="M59.637 69.3651C55.7905 72.9288 50.8303 74.8735 45.5618 74.8735C41.7667 74.8735 38.1258 73.8626 34.9561 71.9694C34.7248 71.8237 34.4849 71.6781 34.2536 71.5239C34.0223 71.3697 33.7996 71.224 33.5683 71.0613C32.6259 70.3845 31.735 69.6306 30.8954 68.7996C30.0559 67.9601 29.2934 67.0606 28.6252 66.1097C28.4625 65.8869 28.3083 65.6556 28.1626 65.4243C28.0084 65.193 27.8628 64.9617 27.7172 64.7219C25.8239 61.5522 24.813 57.9198 24.8216 54.1333C24.8216 48.8648 26.7748 43.896 30.33 40.0581L41.3555 51.0835L42.5034 52.2315L47.4464 57.1745L54.1114 63.8395L59.637 69.3651Z"
            fill="white"
          />
          <path
            d="M54.1198 63.8481L47.4548 57.1831L48.6028 58.3225L53.5458 63.2741L54.1198 63.8481Z"
            fill="white"
          />
          <path
            d="M59.6369 69.3652L54.1199 63.8481L54.6938 64.4221L59.6369 69.3652Z"
            fill="white"
          />
          <path
            d="M47.8066 32.4592L36.4128 43.8531L31.4697 38.91L44.3456 26.0342C44.7739 25.6058 45.5021 25.7429 45.742 26.2997L47.9865 31.5255C48.1235 31.8339 48.055 32.2108 47.8066 32.4592Z"
            fill="white"
          />
          <path
            d="M59.5084 32.9309L42.5033 49.9445L37.5603 45.0015L50.1449 32.4169L56.056 26.5058C56.4843 26.0775 57.2125 26.2146 57.4524 26.7714L59.6969 31.9886C59.8254 32.3141 59.7568 32.6825 59.5084 32.9309Z"
            fill="white"
          />
          <path
            d="M72.1533 32.4766L48.5946 56.0352L43.6516 51.0922L68.6923 26.0429C69.1206 25.6146 69.8488 25.7517 70.0887 26.3085L72.3332 31.5257C72.4703 31.8512 72.4017 32.2282 72.1533 32.4766Z"
            fill="white"
          />
          <path
            d="M75.9567 40.1007C76.0938 40.4262 76.0253 40.7946 75.7768 41.043L69.8572 46.9626L54.694 62.1258L49.751 57.1828L72.3159 34.6094C72.7442 34.181 73.4724 34.3181 73.7123 34.8749L75.9567 40.1007Z"
            fill="white"
          />
          <path
            d="M71.8702 57.1319L60.7848 68.2173L55.8418 63.2743L68.4178 50.6982C68.8462 50.2699 69.5743 50.407 69.8142 50.9638L72.0587 56.1895C72.1958 56.5065 72.1187 56.8834 71.8702 57.1319Z"
            fill="white"
          />
        </svg>
      )}
      {content}
    </Modal>
  );
}
