import { forwardRef } from "react";
import { Button as AriaButton, ButtonProps } from "react-aria-components";

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, className, ...rest }, ref) => {
    return (
      <AriaButton
        {...rest}
        ref={ref}
        className={`transition-[background,border,color] duration-300 *:transition-[background,border,color] ${className}`}
      >
        {children}
      </AriaButton>
    );
  },
);
Button.displayName = "Button";
export default Button;
