import { init, replayIntegration } from "@sentry/react";

init({
  dsn: "https://d57bf9ccb3074e469e0868b954b16419@o769987.ingest.us.sentry.io/5795485",
  integrations: [replayIntegration()],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
