import { ReactNode, useState } from "react";
import Pagination from "@/src/components/Pagination.tsx";
import { RankedPlace } from "@/src/types.ts";
import PlacesListItem from "@/src/components/home/PlacesList/PlacesListItem.tsx";

const placesPerPage = 5;

const sortOptions: {
  label: string;
  sortFn: (a: RankedPlace, b: RankedPlace) => number;
}[] = [
  {
    label: "Best Rank",
    sortFn: (placeA, placeB) => {
      return placeA.average_rank - placeB.average_rank;
    },
  },
  {
    label: "Best Rating",
    sortFn: (placeA, placeB) => {
      return placeB.rating - placeA.rating;
    },
  },
  {
    label: "Review Count",
    sortFn: (placeA, placeB) => {
      return placeA.rating_count.localeCompare(placeB.rating_count);
    },
  },
  {
    label: "Name",
    sortFn: (placeA, placeB) => {
      return placeA.name > placeB.name ? 1 : -1;
    },
  },
];
export default function PlacesList({
  places,
}: {
  places: RankedPlace[] | undefined;
}) {
  const [pageIndex, setPageIndex] = useState(0);
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(0);

  const components: ReactNode[] = [];
  let paginationComponent;

  if (places) {
    const sortedPlaces = [...places].sort(
      sortOptions[selectedFilterIndex].sortFn,
    );
    sortedPlaces
      .slice(
        pageIndex * placesPerPage,
        pageIndex * placesPerPage + placesPerPage,
      )
      .forEach((place, index) => {
        components.push(<PlacesListItem place={place} key={index} />);
      });
    if (places.length > placesPerPage) {
      paginationComponent = (
        <Pagination
          pagesCount={Math.ceil(places.length / placesPerPage)}
          setPageIndex={setPageIndex}
          pageIndex={pageIndex}
        />
      );
    }
  } else {
    for (let i = 0; i < 4; i++) {
      components.push(<PlacesListItem key={i} />);
    }
    paginationComponent = (
      <div className="mx-auto my-4 h-8 w-52 animate-pulse rounded bg-gray-300" />
    );
  }

  return (
    <>
      <div className="flex justify-between border-b border-b-gray-200 p-4">
        <span className="font-medium">Competitor view</span>
        <div className="flex items-center gap-x-2">
          <span className="text-sm">Sort by:</span>
          <select
            id="small"
            className="block rounded-lg border-0 bg-gray-100 px-3 py-1 text-sm"
            value={selectedFilterIndex}
            onChange={(e) => setSelectedFilterIndex(Number(e.target.value))}
          >
            {sortOptions.map(({ label }, index) => {
              return (
                <option key={label} value={index}>
                  {label}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      {components}
      {paginationComponent}
    </>
  );
}
