import { ReactNode, useEffect, useState } from "react";
import Pagination from "@/src/components/Pagination.tsx";
import ToggleKeyword from "@/src/components/home/KeywordsModal/ToggleKeyword.tsx";
import { getRankName } from "@/src/helpers/utils.ts";
import { RANKS } from "@/src/constants.ts";
import AddKeyword from "@/src/components/home/KeywordsModal/AddKeyword.tsx";
import {
  Cell,
  Column,
  Row,
  SortDirection,
  Table,
  TableBody,
  TableHeader,
} from "react-aria-components";
import { Keyword } from "@/src/types.ts";

const keywordsPerPage = 7;

const columns = [
  { name: "Keywords", id: "label", isRowHeader: true, allowsSorting: true },
  { name: "Keywords Difficulty", id: "difficulty", allowsSorting: true },
  { name: "Search Volume", id: "search_volume", allowsSorting: true },
  { name: "Action", id: "active" },
];

function TableCell({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <Cell
      className={`border border-gray-200 px-6 group-hover:bg-blue-50 ${className}`}
    >
      {children}
    </Cell>
  );
}
export default function KeywordsTable({
  keywords,
  searchText,
  canAddKeywords,
  canRemoveKeywords,
}: {
  keywords: Keyword[];
  searchText: string;
  canAddKeywords: boolean;
  canRemoveKeywords: boolean;
}) {
  const [sortDescriptor, setSortDescriptor] = useState<{
    column: keyof Keyword;
    direction: SortDirection;
  }>({
    column: "search_volume",
    direction: "descending",
  });
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    setPageIndex(0);
  }, [searchText, sortDescriptor]);

  let tableContent: ReactNode = (
    <AddKeyword
      keywordLabel={searchText}
      renderTrigger={(addKeyword, isLoading) => (
        <Row className="group" onAction={addKeyword}>
          <TableCell className="w-1/3 py-4 text-sm text-gray-900">
            {searchText}
          </TableCell>
          <TableCell className="py-4">
            <div
              className={`flex items-center gap-x-2 text-sm text-blue-500 ${isLoading && "opacity-50"}`}
            >
              + Click to get information
            </div>
          </TableCell>
          <TableCell className="py-4">
            <div
              className={`flex items-center gap-x-2 text-sm text-blue-500 ${isLoading && "opacity-50"}`}
            >
              + Click to get information
            </div>
          </TableCell>
          <TableCell className="text-center">
            <div
              className={`mx-auto w-24 rounded-lg bg-blue-600 py-2 text-sm text-white ${isLoading && "opacity-50"}`}
            >
              Select
            </div>
          </TableCell>
        </Row>
      )}
    />
  );
  if (keywords.length) {
    const sortedKeywords = sortDescriptor.column
      ? [...keywords].sort((a, b) => {
          if (a.active && b.active) {
            if (sortDescriptor.direction === "ascending") {
              return a[sortDescriptor.column] > b[sortDescriptor.column]
                ? 1
                : -1;
            } else {
              return a[sortDescriptor.column] < b[sortDescriptor.column]
                ? 1
                : -1;
            }
          } else {
            return a.active ? -1 : 1;
          }
        })
      : keywords;
    const paginatedKeywords = sortedKeywords.slice(
      pageIndex * keywordsPerPage,
      pageIndex * keywordsPerPage + keywordsPerPage,
    );
    tableContent = paginatedKeywords.map(
      ({ id, search_volume, label, difficulty, active, state }) => {
        let difficultyContent: ReactNode = "N/A";
        let searchVolumeContent = "N/A";

        if (state === "ready") {
          const { colorClassNames } = RANKS[getRankName(difficulty)];
          difficultyContent = (
            <div className="flex">
              <div
                className={`rounded-md px-2.5 py-0.5 text-xs ${colorClassNames.primary.text} ${colorClassNames.secondary.bg}`}
              >
                {difficulty}
              </div>
            </div>
          );
          searchVolumeContent = search_volume.toString();
        }

        return (
          <Row key={id} className="group">
            <TableCell className="w-1/3 py-4 text-sm text-gray-900">
              {label}
            </TableCell>
            <TableCell className="py-4 text-left text-sm text-gray-500">
              {difficultyContent}
            </TableCell>
            <TableCell className="py-4 text-sm text-gray-500">
              {searchVolumeContent}
            </TableCell>
            <TableCell className="text-center">
              <ToggleKeyword
                isActive={active}
                keywordId={id}
                canAddKeywords={canAddKeywords}
                canRemoveKeywords={canRemoveKeywords}
              />
            </TableCell>
          </Row>
        );
      },
    );
  }

  return (
    <div className="hidden flex-col lg:flex">
      <Table
        aria-label="Keywords"
        sortDescriptor={sortDescriptor}
        onSortChange={(descriptor) =>
          setSortDescriptor({
            column: descriptor.column as keyof Keyword,
            direction: descriptor.direction as SortDirection,
          })
        }
      >
        <TableHeader columns={columns}>
          {({ name, isRowHeader, allowsSorting }) => (
            <Column
              isRowHeader={isRowHeader}
              key={name}
              className={() =>
                `gap-x-2 text-nowrap border border-gray-200 px-6 py-4 text-xs font-normal text-gray-500`
              }
              allowsSorting={allowsSorting}
            >
              {({ sortDirection }) => (
                <>
                  {name}
                  {sortDirection &&
                    (sortDirection === "ascending" ? (
                      <> &#x25B2;</>
                    ) : (
                      <> &#x25BC;</>
                    ))}
                </>
              )}
            </Column>
          )}
        </TableHeader>
        <TableBody className="divide-y">{tableContent}</TableBody>
      </Table>
      {keywords.length > keywordsPerPage && (
        <Pagination
          pagesCount={Math.ceil(keywords.length / keywordsPerPage)}
          setPageIndex={(index) => setPageIndex(index)}
          pageIndex={pageIndex}
        />
      )}
    </div>
  );
}
