import { Keyword as KeywordType } from "@/src/types.ts";
import { useAppStore } from "@/src/helpers/useAppStore.ts";
import Button from "../base/Button";
import Tooltip from "../base/Tooltip";
import { useNavigate } from "@tanstack/react-router";

import ArrowsRotateIcon from "fontawesome/solid/arrows-rotate.svg?react";
import BoltIcon from "fontawesome/solid/bolt.svg?react";

const commonClassName = "h-[34px] lg:h-[38px] rounded-lg";
export default function Keyword({
  keyword,
  isMaxLimitReached,
  isSelected,
  openKeywordsDialog,
}:
  | {
      keyword?: never;
      isMaxLimitReached?: never;
      isSelected?: never;
      openKeywordsDialog?: never;
    }
  | {
      keyword: KeywordType;
      isMaxLimitReached: boolean;
      isSelected: boolean;
      openKeywordsDialog: () => void;
    }) {
  const navigate = useNavigate();

  if (!keyword) {
    return (
      <div
        className={`w-[180px] animate-pulse bg-gray-300 ${commonClassName}`}
      />
    );
  }
  const { label, id, grid_state, snapshot_dates } = keyword;

  if (keyword.active) {
    const isGeneratingGrid =
      grid_state === "pending" || grid_state === "processing";

    let className = isSelected ? "bg-blue-600 text-white" : "text-blue-600";

    if (!isGeneratingGrid) {
      className += " data-[hovered]:bg-blue-600 data-[hovered]:text-white";
    }

    return (
      <Tooltip
        placement="top"
        isDisabled={!isGeneratingGrid}
        renderTriggerElement={(props) => (
          <Button
            {...props}
            className={`flex items-center gap-2 text-nowrap border border-blue-600 px-3 text-sm ${commonClassName} ${className}`}
            onPress={() => {
              if (!isGeneratingGrid) {
                navigate({
                  search: (prevSearch) => ({
                    ...prevSearch,
                    selectedKeywordId: id,
                    date: snapshot_dates[0],
                  }),
                });
              }
            }}
          >
            {label}
            {isGeneratingGrid && (
              <ArrowsRotateIcon className="w-3 animate-spin fill-blue-600" />
            )}
          </Button>
        )}
      >
        Getting the keyword ranking data
      </Tooltip>
    );
  }
  return (
    <Button
      onPress={() =>
        isMaxLimitReached
          ? useAppStore.getState().setIsUpsellModalOpen(true)
          : openKeywordsDialog()
      }
      className={`group flex items-center gap-x-2 text-nowrap rounded-lg border border-dashed px-3 text-sm data-[hovered]:text-white ${commonClassName} ${isMaxLimitReached ? "border-purple-500 text-purple-500 data-[hovered]:border-purple-500 data-[hovered]:bg-purple-500" : "border-gray-400 text-gray-400 data-[hovered]:border-blue-600 data-[hovered]:bg-blue-600"}`}
    >
      {isMaxLimitReached && (
        <BoltIcon className="w-3 fill-purple-500 group-data-[hovered]:fill-white" />
      )}
      {label}
    </Button>
  );
}
