import { cloneElement } from "react";
import Button from "@/src/components/base/Button.tsx";
import { Link } from "@tanstack/react-router";

/*
import ChartSimpleIcon from "fontawesome/solid/chart-simple.svg?react";
*/
import HouseIcon from "fontawesome/solid/house.svg?react";

const navItems = [
  {
    label: "home",
    icon: <HouseIcon />,
  },
  /*{
    label: "reviews",
    icon: <ChartSimpleIcon />,
  },*/
];

export default function NavLinks() {
  return (
    <>
      {navItems.map(({ label, icon }) => {
        return (
          <Button key={label} className="group">
            <Link
              className={`group flex items-center gap-x-2 px-4 py-6 data-[status=active]:bg-blue-50 group-data-[hovered]:bg-blue-50 lg:size-11 lg:justify-center lg:rounded-lg lg:p-0`}
              to={`/$placeId/${label}`}
            >
              {cloneElement(icon, {
                className: `w-4 lg:w-6 fill-gray-900 group-data-[status=active]:fill-blue-600 lg:group-data-[hovered]:fill-blue-600 lg:fill-gray-300`,
              })}

              <span className="text-sm font-medium capitalize group-data-[status=active]:text-blue-600 lg:hidden">
                {label}
              </span>
            </Link>
          </Button>
        );
      })}
    </>
  );
}
