import { cloneElement } from "react";
import { useAppStore } from "@/src/helpers/useAppStore.ts";
import Modal from "@/src/components/base/Modal.tsx";
import usePlaceMutations from "@/src/datahooks/usePlaceMutations.tsx";
import { useSelectedPlace } from "@/src/contexts.ts";
import { openChargebeeHostedPage } from "@/src/helpers/utils.ts";
import useChargebeeUpdates from "@/src/datahooks/useChargebeeUpdates.tsx";
import ModalLoading from "@/src/components/ModalLoading.tsx";

import TruckIcon from "fontawesome/solid/truck.svg?react";
import ClipboardIcon from "fontawesome/solid/clipboard.svg?react";
import TagIcon from "fontawesome/solid/tag.svg?react";

const upsellFeatures = [
  {
    icon: <TagIcon />,
    label: "+7 target Keywords",
  },
  {
    icon: <TruckIcon />,
    label: "+70 daily Drives",
  },
  {
    icon: <ClipboardIcon />,
    label: "+7 monthly Reviews",
  },
];
export default function UpsellModal() {
  const isOpen = useAppStore((state) => state.isUpsellModalOpen);
  const { selectedPlace } = useSelectedPlace();
  const { upsellPlace } = usePlaceMutations();
  const { onUpsellSubscription, isLoading } = useChargebeeUpdates();

  function onUpsell() {
    upsellPlace({ placeId: selectedPlace.id }).then((hostedPage) => {
      useAppStore.getState().setIsUpsellModalOpen(false);
      openChargebeeHostedPage(
        hostedPage,
        async () => {
          await onUpsellSubscription(selectedPlace.id);
          useAppStore.getState().setIsUpsellModalOpen(false);
        },
        () => useAppStore.getState().setIsUpsellModalOpen(true),
      );
    });
  }

  const content = isLoading ? (
    <ModalLoading />
  ) : (
    <>
      <img src="/upsell.png" alt="" className="mb-4" />
      <span className="mb-2 text-lg font-medium">
        Upgrade to the Growth plan ($99/mo)
      </span>
      <span className="text-sm text-gray-400">
        Unlock the full potential of the features you&apos;re already using. If
        you&apos;re still unsure - that&apos;s a 233.33% upgrade in performance,
        to be exact
      </span>
      <div className="mt-4 flex gap-2 border-t border-t-gray-200 py-4 ">
        {upsellFeatures.map(({ icon, label }) => {
          return (
            <div
              key={label}
              className="flex flex-1 items-center gap-2 rounded-lg bg-blue-50 p-3 text-sm text-blue-600"
            >
              {cloneElement(icon, { className: "fill-blue-600 h-3" })}
              {label}
            </div>
          );
        })}
      </div>
      <div className="mx-auto flex w-3/4 gap-4">
        <button
          className="flex-1 rounded-lg border border-gray-200 px-5 py-2.5 text-sm font-medium text-gray-900"
          onClick={() => useAppStore.getState().setIsUpsellModalOpen(false)}
        >
          Maybe next time
        </button>
        <button
          className="flex-1 rounded-lg bg-blue-600 px-5 py-2.5 text-sm font-medium text-white"
          onClick={onUpsell}
        >
          Get started
        </button>
      </div>
    </>
  );
  return (
    <Modal
      className="w-[650px] justify-center p-6"
      isOpen={isOpen}
      onClose={() => useAppStore.getState().setIsUpsellModalOpen(false)}
    >
      {content}
    </Modal>
  );
}
