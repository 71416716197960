import { useAppStore } from "@/src/helpers/useAppStore.ts";
import { useSelectedPlace } from "@/src/contexts.ts";

import TriangleExclamationIcon from "fontawesome/solid/triangle-exclamation.svg?react";
import InfoCircleIcon from "fontawesome/regular/circle-info.svg?react";

export default function Banners() {
  const { isDummyPlace, selectedPlace } = useSelectedPlace();

  let icon,
    text,
    onClick,
    className,
    buttonClassName,
    buttonText,
    shouldRender = false;

  if (isDummyPlace) {
    shouldRender = true;
    className = "bg-yellow-300";
    text =
      "This is a DEMO version of the dashboard. Please connect your GMB profile to start using HeyRank";
    onClick = () => useAppStore.getState().setIsAddModalOpen(true);
    icon = <TriangleExclamationIcon className="w-4 fill-gray-900" />;
    buttonClassName = "bg-blue-600";
    buttonText = "Connect";
  } else if (selectedPlace.subscription.status === "cancelled") {
    shouldRender = true;
    className = "bg-red-100";
    text =
      "If you want to continue ranking your business in the local search, reactivate your subscription!";
    onClick = () => useAppStore.getState().setIsReactivateModalOpen(true);
    icon = <InfoCircleIcon className="w-4 fill-red-600" />;
    buttonClassName = "bg-red-600";
    buttonText = "Reactivate";
  }

  if (shouldRender) {
    return (
      <div
        className={`mx-[-16px] mt-[-16px] flex items-center gap-2 p-4 text-sm lg:m-0 lg:h-[62px] lg:gap-4 lg:rounded-lg lg:py-0 ${className}`}
      >
        {icon}
        {text}
        <button
          className={`text-nowrap rounded-lg px-4 py-2.5 text-sm text-white ${buttonClassName}`}
          onClick={onClick}
        >
          {buttonText}
        </button>
      </div>
    );
  }
}
