import CarIcon from "fontawesome/solid/car.svg?react";
import CheckmarkIcon from "fontawesome/solid/circle-check.svg?react";

export default function DrivesCount({
  drives,
  drivesRemaining,
}: {
  drives: number;
  drivesRemaining: number;
}) {
  const isCompleted = drivesRemaining === 0;
  const drivesDone = drives - drivesRemaining;
  return (
    <div
      className={`flex items-center space-x-2 rounded-lg border px-3 py-2 text-base font-medium lg:px-6 ${isCompleted ? "border-green-500" : "border-gray-200"}`}
    >
      <CarIcon className="w-4" />
      <span className="text-nowrap text-base font-medium">
        <span className="md:hidden">
          {drivesDone}/{drives}
        </span>
        <span className="hidden md:inline">
          {isCompleted ? "All" : `${drivesDone}/${drives}`} drives done
        </span>
      </span>
      {isCompleted && <CheckmarkIcon className="w-4 fill-green-500" />}
    </div>
  );
}
