import { Rating } from "flowbite-react";

export default function ReviewStars({
  rating,
  isLoading,
}:
  | {
      rating: number;
      isLoading?: boolean;
    }
  | {
      rating?: unknown;
      isLoading: true;
    }) {
  if (isLoading) {
    return (
      <Rating>
        <Rating.Star filled={false} className="animate-pulse" />
        <Rating.Star filled={false} className="animate-pulse" />
        <Rating.Star filled={false} className="animate-pulse" />
        <Rating.Star filled={false} className="animate-pulse" />
        <Rating.Star filled={false} className="animate-pulse" />
      </Rating>
    );
  }
  return (
    <Rating>
      <Rating.Star filled={rating >= 1} />
      <Rating.Star filled={rating >= 2} />
      <Rating.Star filled={rating >= 3} />
      <Rating.Star filled={rating >= 4} />
      <Rating.Star filled={rating === 5} />
    </Rating>
  );
}
