import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DetailedPlace, Place, SubscriptionStatus } from "@/src/types.ts";
import { getQueryKeys } from "@/src/helpers/getQueryKeys.ts";
import { produce } from "immer";
import { get } from "@/src/helpers/HTTP.ts";
import { useState } from "react";

async function updateAndGetPlaceRequest(
  placeId: string,
): Promise<DetailedPlace> {
  const { place } = await get(`v1/places/${placeId}?skip_cache=true`);
  return place;
}
export default function useChargebeeUpdates() {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const { mutateAsync: updateAndGetPlace, isPending: isUpdatingPlace } =
    useMutation({
      mutationFn: (placeId: string) => updateAndGetPlaceRequest(placeId),
    });
  function pollPlace(placeId: string): Promise<DetailedPlace> {
    setIsLoading(true);
    return new Promise((resolve) => {
      (async function waitForPlace() {
        const newPlace = await updateAndGetPlace(placeId);
        if (newPlace.keywords.length) {
          setIsLoading(false);
          return resolve(newPlace);
        }
        setTimeout(waitForPlace, 2000);
      })();
    });
  }
  async function onAddPlace(placeId: string) {
    const newPlace = await pollPlace(placeId);
    queryClient.setQueryData(getQueryKeys().places.details(placeId), newPlace);
    queryClient.setQueryData<Place[]>(
      getQueryKeys().places.list(),
      (prevData) => {
        if (prevData) {
          return [...prevData, newPlace];
        }
      },
    );
  }
  function onSubscriptionStatusChange(
    subscriptionId: string,
    subscriptionStatus: SubscriptionStatus,
  ) {
    queryClient.setQueryData<Place[]>(
      getQueryKeys().places.list(),
      (places) => {
        if (places) {
          return produce(places, (draftPlaces) => {
            for (let i = 0; i < draftPlaces.length; i++) {
              if (draftPlaces[i].subscription.id === subscriptionId) {
                draftPlaces[i].subscription.status = subscriptionStatus;
                break;
              }
            }
          });
        }
      },
    );
  }
  async function onUpsellSubscription(placeId: string) {
    const newPlace = await updateAndGetPlace(placeId);
    queryClient.setQueryData(getQueryKeys().places.details(placeId), newPlace);
    queryClient.refetchQueries({ queryKey: ["keywordDetails"] });
  }

  return {
    onAddPlace,
    onSubscriptionStatusChange,
    onUpsellSubscription,
    isLoading: isUpdatingPlace || isLoading,
  };
}
