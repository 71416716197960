import { useEffect, useRef } from "react";
import mapboxgl, { Marker } from "mapbox-gl";
import { createRoot } from "react-dom/client";
import { type GridPoint as GridPointType } from "@/src/types.ts";
import { getRankName } from "@/src/helpers/utils.ts";
import { RANKS } from "@/src/constants.ts";
import { useSelectedPlace } from "@/src/contexts.ts";
import GridPoint from "./GridPoint.tsx";

function Tooltip(props: Pick<GridPointType, "places"> | { text: string }) {
  return (
    <div className="flex flex-col space-y-3 rounded-lg bg-gray-900/90 p-4">
      {"places" in props ? (
        props.places.map(({ rank, name, owner }) => {
          const { colorClassNames } = rank
            ? RANKS[getRankName(rank)]
            : RANKS.low;

          return (
            <div className="flex items-center gap-x-2" key={name}>
              <div
                className={`flex size-6 shrink-0 items-center justify-center rounded text-sm text-white ${colorClassNames.primary.bg} ${owner ? "font-bold" : "font-normal"}`}
              >
                {rank ? rank : "21+"}
              </div>
              <span
                className={`text-sm text-white ${owner ? "font-bold" : "font-normal"}`}
                key={name}
              >
                {name}
              </span>
            </div>
          );
        })
      ) : (
        <span className="text-sm font-normal text-white">{props.text}</span>
      )}
    </div>
  );
}
export default function GridMap({
  gridPoints,
}: {
  gridPoints: GridPointType[] | undefined;
}) {
  const { selectedPlace } = useSelectedPlace();
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<mapboxgl.Map>();
  const tooltipRef = useRef(
    new mapboxgl.Popup({ offset: 25, closeButton: false, closeOnClick: false }),
  );
  useEffect(() => {
    mapboxgl.accessToken =
      "pk.eyJ1Ijoibmlrb2xhY2ljbWlsIiwiYSI6ImNsdnA5NWcxZDByNmgycW82eHEwYXk2bzAifQ.5B3djX0vly6PJB30RePMMA";

    const map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/streets-v12",
      zoom: 14.4,
      maxZoom: 15.5,
      minZoom: 14.4,
      cooperativeGestures: true,
      pitchWithRotate: false,
      dragRotate: false,
      touchZoomRotate: false,
    });

    mapRef.current = map;
    const updatePadding = () => {
      if (mapContainerRef.current) {
        const hasPadding = window.innerWidth >= 1024;
        map.setPadding({
          top: hasPadding ? mapContainerRef.current.clientHeight / 10 : 0,
          bottom: 0,
          left: 0,
          right: hasPadding ? mapContainerRef.current.clientWidth / 2.5 : 0,
        });
      }
    };
    updatePadding();

    window.addEventListener("resize", updatePadding);
    return () => {
      map.remove();
      window.removeEventListener("resize", updatePadding);
    };
  }, []);

  useEffect(() => {
    if (selectedPlace && mapRef.current) {
      const { longitude, latitude } = selectedPlace.location;

      mapRef.current.setCenter({ lon: longitude, lat: latitude });
      mapRef.current.setZoom(14.4);
      /*mapRef.current.setMaxBounds([
        [longitude - 0.025, latitude - 0.025],
        [longitude + 0.025, latitude + 0.025],
      ]);*/
    }
  }, [selectedPlace]);

  useEffect(() => {
    const markers: Marker[] = [];
    if (gridPoints && mapRef.current) {
      const tooltipNode = document.createElement("div");
      const tooltipRoot = createRoot(tooltipNode);

      gridPoints.map(({ latitude, longitude, rank, places, upgrade }) => {
        const wrapperElement = document.createElement("div");
        const markerCoords = {
          lng: longitude,
          lat: latitude,
        };
        const tooltipComponent = upgrade ? (
          <Tooltip
            text={`See the full impact of ${selectedPlace.name} ranking by upgrading to the ${upgrade}`}
          />
        ) : (
          <Tooltip places={places} />
        );
        const isCenterPoint =
          latitude === selectedPlace.location.latitude &&
          longitude === selectedPlace.location.longitude;

        createRoot(wrapperElement).render(
          <GridPoint
            isCenterPoint={isCenterPoint}
            onMouseEnter={() => {
              tooltipRoot.render(tooltipComponent);
              tooltipRef.current.setLngLat(markerCoords);
              tooltipRef.current.addClassName("opacity-100");
            }}
            onMouseLeave={() => {
              tooltipRef.current.removeClassName("opacity-100");
            }}
            upgrade={upgrade}
            rank={rank}
          />,
        );
        const marker = new mapboxgl.Marker(wrapperElement);
        markers.push(marker);
        marker.setLngLat(markerCoords).addTo(mapRef.current as mapboxgl.Map);
      });
      tooltipRef.current
        .setDOMContent(tooltipNode)
        .addTo(mapRef.current)
        .addClassName("opacity-0 transition-opacity duration-300");
    }
    return () => {
      markers.forEach((marker) => {
        marker.remove();
      });
    };
  }, [gridPoints]);

  return (
    <div className="absolute inset-0 size-full" ref={mapContainerRef}>
      <div id="map" className="size-full"></div>
    </div>
  );
}
