import { ReactNode, useEffect, useState } from "react";
import { Keyword as KeywordType } from "@/src/types.ts";
import KeywordsModal from "@/src/components/home/KeywordsModal/KeywordsModal.tsx";
import { useNavigate } from "@tanstack/react-router";
import Keyword from "./Keyword";

export default function Keywords({
  data,
  selectedKeywordId,
  isDisabled,
}: {
  data:
    | {
        keywords: KeywordType[];
        maxKeywords: number;
      }
    | undefined;
  selectedKeywordId: string | undefined;
  isDisabled: boolean;
}) {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const sortedKeywords = data
    ? [...data.keywords].sort((a, b) => {
        if (a.active && b.active) {
          return b.search_volume - a.search_volume;
        } else {
          return a.active ? -1 : 1;
        }
      })
    : [];

  useEffect(() => {
    if (
      !selectedKeywordId &&
      sortedKeywords.length &&
      ["ready", "failed"].includes(sortedKeywords[0].grid_state)
    ) {
      setTimeout(() => {
        navigate({
          search: (prevSearch) => ({
            ...prevSearch,
            selectedKeywordId: sortedKeywords[0].id,
            date: sortedKeywords[0].snapshot_dates[0],
          }),
        });
      }, 1);
    }
  }, [selectedKeywordId, sortedKeywords]);

  const components: ReactNode[] = [];

  if (!data) {
    for (let i = 0; i < 5; i++) {
      components.push(<Keyword key={i} />);
    }
  } else {
    sortedKeywords.slice(0, 10).forEach((keyword) => {
      const isSelected = selectedKeywordId === keyword.id;

      components.push(
        <Keyword
          key={keyword.id}
          keyword={keyword}
          isMaxLimitReached={
            sortedKeywords.filter(({ active }) => !!active).length ===
            data.maxKeywords
          }
          openKeywordsDialog={() => setIsModalOpen(true)}
          isSelected={isSelected}
        />,
      );
    });
  }

  return (
    <>
      <div
        className={`flex border-b border-b-gray-200 pb-2 ${(isDisabled || !data) && "pointer-events-none opacity-50"}`}
      >
        <span className="text-xs text-gray-400 lg:text-base">Keywords</span>
        <KeywordsModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          keywords={data ? data.keywords : []}
          maxKeywords={data ? data.maxKeywords : 0}
        />
      </div>
      <div
        className={`flex w-full gap-x-3 overflow-x-auto lg:overflow-x-hidden ${isDisabled && "pointer-events-none opacity-50"}`}
      >
        {components}
      </div>
    </>
  );
}
